import React, { useState } from "react";
import PropTypes from "prop-types"

import Grid from '@material-ui/core/Grid';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

import SelectUser from "components/SelectUser"

const theme = createMuiTheme({
    palette: {
        primary: blue,
        secondary: blue,
    },
});
  
  
class InviteForm extends React.Component {
    state = {
    }

    // when user selects an item from the dropdown
    handleSelectUser = (selection) => {
        //this.setState({ lat: selection.geometry.coordinates[0] });
        //this.setState({ lng: selection.geometry.coordinates[1] });
        //this.setState({ zoom: 2.5 });
        //this.setState({ countryId: selection.properties.id });
    }

    render () {

        const csrfToken = ( document.querySelectorAll('meta[name=csrf-token]').length > 0 )
            ? document.querySelector('meta[name=csrf-token]').getAttribute('content')
            : ""
        
        const errors = JSON.parse( this.props.errors )

        return (
            <React.Fragment>
                <MuiThemeProvider theme={theme}>
                    <form     
                        id="invite_page_admin" 
                        autoComplete="off" 
                        noValidate="novalidate" 
                        action= {this.props.action}
                        method="post">

                        <input type='hidden' name='utf8' value='✓' />
                        <input type='hidden' name='authenticity_token' value={csrfToken} />

                        <Grid container spacing={3}>
                            
                            <Grid item xs={10}>
                                <SelectUser 
                                    name = "acts_as_claim[user_id]"
                                    label = "Email"
                                    remoteUrl = {this.props.remoteUrl}
                                    onSelectUser = { this.handleSelectUser }
                                    helperText = { "user" in errors ? `User ${errors.user}` : undefined } 
                                    error = { "user" in errors ? true : false }
                                />
                            </Grid>

                            <Grid item xs={2}>
                                <input type="submit" name="commit" type="submit" value="Invite" className="btn btn-secondary" />
                            </Grid>
                        
                        </Grid>

                    </form>
                </MuiThemeProvider>
            </React.Fragment>
        )

    }

}

InviteForm.propTypes = {
    errors: PropTypes.string,
}

InviteForm.defaultProps = {
    options: [],
    name: "",
    label: "",
    canCreateNew: false,
    formatValueToDisplay: undefined,
    handleOnChange: undefined,
    errors: '{}',
    method: "post"
}

export default InviteForm