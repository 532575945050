// https://gist.github.com/cseelus/63baa4f46f6f0ae0c4630a3987022f97
import { CustomUploader } from './custom_uploader'
import { CropperInit } from './cropper_init';
import { ResizeImage } from './resize_image';

//import {Dropzone} from 'dropzone/dist/dropzone';

const onDrop = (event) => {
    event.preventDefault()
    const files = event.dataTransfer.files;
    //Array.from(files).forEach(file => uploadFile(file))
    Array.from(files).forEach(file => alert(file.name))
}


// Handle change, e.g. User attaches a file
document.addEventListener("turbolinks:load", function () {

    


    // initialize cropper.js on all images with .cropper-image-preview
    const croppers = Array.from(document.querySelectorAll('img.cropper-image-preview'))
    croppers.forEach(cropper => {
        // we need to get the id of the attached input
        // and use this to prefix the crop-x inputs
        var imgId = cropper.id
        var input = document.querySelector(`input[data-crop-container=${imgId}]`)
        var inputId = input.id

        var options = {
            data: {
                x: Number( document.querySelector(`#${inputId}_crop_x`).value ),
                y: Number( document.querySelector(`#${inputId}_crop_y`).value ),
                width: Number( document.querySelector(`#${inputId}_crop_w`).value ),
                height: Number( document.querySelector(`#${inputId}_crop_h`).value )
            }
        }

//        // we can override the cropper default options by merging 
//        // the cropOptions data attributes
//        if (input.hasAttribute("data-crop-options")) {
//            var newOptions = JSON.parse( input.dataset.cropOptions )
//            console.log("newOptions")
//            console.log(newOptions)
//            options = Object.assign({}, options, newOptions)
//            console.log(options)
//        }



        
        if (cropper.dataset.hasImage != "false" ) {
            console.log("---initialize cropper---")
            const crop = new CropperInit(cropper, inputId, options)
            crop.init(cropper)
        }
        
    })

    // when user attaches a file we: 
    // 1. resize it
    // 2. refresh the cropper with the new and resized image 
    // 3. initiate direct upload to cloud storage
    
    // we initialize on all file inputs with .direct-upload
    const inputs = Array.from(document.querySelectorAll('input[type=file].direct-upload'))
    inputs.forEach(input => {
        
        // on file change...
        input.addEventListener('change', event => {
            
            // ... for each file selected ...
            Array.from(input.files).forEach(file => {
                console.log("---initialize file select---")
                var filename = file.name

                // we reset the input now, so that only one 
                // image is submitted later 
                input.value = ""

                // ... we resize it 
                let resized = new ResizeImage(file)
                // ResizeImage makes use of FileReader which is 
                // asynchrous. To use the result of its onload
                // event, we pass the name of a function (afterResize) 
                // that will be called on the result. We also 
                // pass file input field as this is used by the 
                // later functions. 
                
                resized.process(input,filename,afterResize)

                
            })
            // clear the selected files from the input
            
        })
    })

});

function afterResize(input,filename,resizedFile) {
    
    // we get the attribute name from the input name=object[attribute]
    // we use this to prefix the crop_x input selectors in CropperInit
    const attribute = input.id 
    //const attribute = name.substring(name.lastIndexOf("[")).match(/\[(.*?)\]/)[1]

    // REPLACE THE CROPPER IF IT EXISTS 

    // we check whether the input has a cropper defined it 
    if ( input.hasAttribute("data-crop-container") ) {
        // and that the crop container exists on the page
        const cropContainers = Array.from(document.querySelectorAll("img#"+input.dataset.cropContainer))
        cropContainers.forEach(container => {
            console.log("---reinitialize cropper---")
            // update the image src with the newly resizedImage data url
            container.src = resizedFile
            container.dataset.hasImage = "true"
            container.classList.remove("d-none")
            input.dataset.hasImage = "true"
            // drop the existing Cropper.js initialization
            if ( container.hasOwnProperty('cropper' ) ) {
                container.cropper.destroy()
            }
            
            // and reinitialize
            const crop = new CropperInit(container,attribute)
            crop.init(container)
        })
    }   

    // we check whether the input has a preview container defined it 
    if (input.hasAttribute("data-preview-container")) {
        // and that the preview container exists on the page
        const previewContainers = Array.from(document.querySelectorAll("div#" + input.dataset.previewContainer))
        previewContainers.forEach(container => {
            
        })
    }
    
    
    // build a file object from the resized data URI
    const file = dataURItoBlob(filename,resizedFile)

    // start the upload
    const uploader = new CustomUploader(input, file)
    uploader.start(file)

}

// as we need a file object for direct upload to activestorage
// we need to convert the Data URI back to a file (a blob)
function dataURItoBlob(filename,dataURI) {
    
    // convert base64 to raw binary data held in a string
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ia], { type: mimeString })
    var file = new File([blob], filename)

    return file 
}




addEventListener("direct-upload:initialize", event => {
    // when direct upload is initialized ...
    const { target, detail } = event
    const { id, file, src } = detail

    console.log("CHECK THIS !!!!!")
    console.log(detail)
    
    var form        = target.closest('form')

    // if there are progress containers on the form
    const prgrssCntnrs = Array.from(form.querySelectorAll('.progress-container') )
    prgrssCntnrs.forEach(prgrssCntnr => {
        // ... we display a progress bar 
        prgrssCntnr.innerHTML = `
            <div id="direct-upload-${id}" class="d-block progress" style="height:5px;">
                <div id="direct-upload-progress-${id}" class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <div id="direct-upload-badge-${id}" class="d-none">
                <span title="File successfuly uploaded" class="badge badge-pill badge-success">
                    <i class="fas fa-check"></i>
                </span>
            </div>
        `
    })

    // if there are submit buttons on the form
    const submitBtns = Array.from(form.querySelectorAll('input[type=submit]'))
    submitBtns.forEach(submitBtn => {
        // ... we disable the form while uploading the image ...
        submitBtn.setAttribute("value", "Uploading...")
        submitBtn.setAttribute("disabled", "")
    })

    console.log("---display preview 11 ---")
    // if there are preview containers on the page
    const prvwCntnrs = Array.from(document.querySelectorAll("#"+target.dataset.previewContainer))
    prvwCntnrs.forEach(container => {
        console.log("---display preview---")

        // build the progress bar 

        var prgCtnr = document.createElement('div')
        prgCtnr.id = `direct-upload-${id}`
        prgCtnr.classList.add("d-block", "progress")
        prgCtnr.style = "height:5px;" 
        
        var prg = document.createElement('div')
        prg.id = `direct-upload-progress-${id}`
        prg.classList.add("progress-bar")
        prg.role = "progressbar"
        prg.ariaValuenow = "0" 
        prg.ariaValuemin = "0"
        prg.ariaValuemax="100"

        prgCtnr.insertAdjacentElement("beforeend", prg)
        
        
        // build the img
        var img = new Image
        // convert the blob back to a data URI
        blobToDataURL(file, (dataurl) => {
            //img.src = resizedFile
            img.src = dataurl
        })
        
        var fig = document.createElement('figure')
        fig.classList.add("img-grid","img-grid-square")

        var caption = document.createElement('figcaption')
        caption.insertAdjacentElement("beforeend", prgCtnr)

        var html = document.createElement('div') 
        html.id = `direct-upload-preview-${id}`
        html.classList.add("col-1","img-grid-container")
        html.dataset.filename = file.name 

        fig.insertAdjacentElement("beforeend", img)
        fig.insertAdjacentElement("beforeend", caption)
        html.insertAdjacentElement("beforeend", fig)


//            '< div class='col-1 img-grid-container' id = 'direct-upload-preview-"+filename+"' >
//                <figure class='img-grid img-grid-square'>
//                    <img src='"+preview_path+"' alt='"+filename+"'>
//                        <figcaption>
//                        </figcaption>
//        </figure>
//      </div>'
//
//        
//        
//
//        var html = document.createElement('div')
//        
//        //html.insertAdjacentElement("beforeend", img)
//
//        var inner = document.createElement('div')
//        inner.style = 'background-color: red;width: 100%;padding-top: 100%;position: relative;'
//        inner.insertAdjacentElement("beforeend", img)
//
//        html.insertAdjacentElement("beforeend", inner)
        //            html.innerHTML = `
        //                <div style='background-color: red;width: 100%;padding-top: 100%;position: relative;'>
        //                    ${img}
        //                </div>
        //            `
        //img.height = "120"
        //img.width = "120"
        //container.insertAdjacentElement("beforeend", img)
        container.insertAdjacentElement("beforeend", html)

        var emptyCntnr = document.querySelector("#empty_asset_container")
        if ( emptyCntnr ) { 
            emptyCntnr.parentNode.removeChild(emptyCntnr)
        }
        
    })



    //        < div id = "direct-upload-${id}" class="direct-upload direct-upload--pending" >
    //            <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
    //            <span class="direct-upload__filename">${file.name}</span>
    //    </div >

    
})

addEventListener("direct-upload:start", event => {
    // when upload starts we remove the pending class (not currently used)
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    if (element) {
        element.classList.remove("direct-upload--pending")
    }
})

addEventListener("direct-upload:progress", event => {
    // update the progress bar width
    const { id, progress } = event.detail
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    if (progressElement) {
        progressElement.style.width = `${progress}%`
    }
})

addEventListener("direct-upload:error", event => {
    event.preventDefault()
    const { id, error } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    if (element) {
        element.classList.add("direct-upload--error")
        element.setAttribute("title", error)
    }
})

addEventListener("direct-upload:end", event => {
    const { id } = event.detail
    const progressBar = document.getElementById(`direct-upload-${id}`)
    const badge = document.getElementById(`direct-upload-badge-${id}`)
 
    if (progressBar) {
        const form = progressBar.closest('form')
        const submitBtn = form.querySelector('input[type=submit]')


        // hide the progressbar 
        progressBar.classList.add("direct-upload--complete")
        progressBar.classList.add("d-none")
        progressBar.classList.remove("d-block")

        // enable the form 
        submitBtn.setAttribute("value", "Save")
        submitBtn.removeAttribute("disabled")
    }

    if (badge) {
        // display the success badge 
        badge.classList.remove("d-none")
        badge.classList.add("d-block")
    }
       

    
})



//**blob to dataURL**
function blobToDataURL(blob, callback) {
    var a = new FileReader();
    a.onload = function (e) { callback(e.target.result); }
    a.readAsDataURL(blob);
}