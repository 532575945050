import Shuffle from 'shufflejs';
import imagesLoaded from 'imagesloaded';


document.addEventListener("turbolinks:load", function () {
    //var Shuffle = window.Shuffle
//    var container = document.getElementById('shuffle_gallery_containerxx')
//    var options = {
//        itemSelector: '.asset',
//        sizer: '.gallery-sizer-element'
//    }
//    const shuffleInstance = window.Shuffle(container, options)
    const containers = Array.from(document.querySelectorAll('#shuffle_gallery_container'))
    //const container = document.getElementById('shuffle_gallery_container')
    containers.forEach(container => {
    
        var imgLoad = imagesLoaded(container)

        const shuffleInstance = new Shuffle(container, {
            itemSelector: '.asset',
            sizer: '.gallery-sizer-element',
            gutterWidth: 10,
            buffer: 80
        });

        imagesLoaded(document.querySelector('#container'), function (instance) {
            console.log('all images are loaded');
        });

        imgLoad.on('always', (instance) => {
            shuffleInstance.layout()
        });
    })

})



