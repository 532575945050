import React, { useState } from "react";
import PropTypes from "prop-types"

import SelectRemote from "components/SelectRemote"
import MenuItem from '@material-ui/core/MenuItem';

import Avatar from '@material-ui/core/Avatar';


class SelectUser extends React.Component {
    state = {
        fetchedData: this.props.fetchedData,
        searchCache: this.props.searchCache,
        selectedItem: this.props.selectedItem,
        inputValue: ""
    };

    
    

 
    renderSuggestion = (suggestionProps) => {
        const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
        const isHighlighted = highlightedIndex === index;
        
        // if we try to access the selectedItem.id directly it raises an error 
        // on page load because the id does not exist. selectedItem = []
        // so we check if selectedItem exists
        const selectedItemId = selectedItem ? selectedItem.id : ''
        const suggestionId = suggestion ? suggestion.id : ''
        
        const isSelected = selectedItemId === suggestionId //(selectedItemId).indexOf(suggestion ) > -1;
      
        return (
            <MenuItem 
                className= "row"
                {...itemProps}
                key={suggestion.id}
                selected={isHighlighted}
                component="div"
                style={{
                    fontWeight: isSelected ? 500 : 400,
                }}
            >

                <div className="col-2 text-center">
                    <Avatar 
                        alt={suggestion.name}
                        src={suggestion.avatar}
                        style={{  margin: 0, backgroundColor:"#134886"}}
                        >
                        {suggestion.name.match(/\b(\w)/g).join('').toUpperCase()}
                        
                    </Avatar>
                </div>
                <div className="col-10">
                    <p className="mb-0"><b>{suggestion.name}</b></p>
                    <div className="row">
                        <div className="col d-flex justify-content-between">
                            <p className="mb-0 text-left">
                                <small></small>
                            </p>
                            <p className="mb-0 text-right">
                                { //[...Array(suggestion.rating)].map((e, i) => <i className="fas fa-star" key={i}></i>) 
                                }
                                { //[...Array(5-suggestion.rating)].map((e, i) => <i className="far fa-star" key={i}></i>) 
                                }
                            </p>
                        </div>
                    </div>
                    
                </div>

            </MenuItem>



        );
    }

    



    formatValueToDisplay = (item) => {
        return item 
            ? `${item.name}`
            : ""
    }

    // when user selects an item from the dropdown
    handleRemoteItemChange = (selection) => {

        const isNew = ('status' in selection && selection.status === "_new")

        this.setState({ isNew: isNew });

        // we run the parent function if defined
        if (typeof this.props.onSelectUser === 'function') {
            this.props.onSelectUser(selection);
        }

        if (isNew) {
            this.setState({ inputName: "acts_as_claim[email]" }) 
            
        } else {
            this.setState({ inputName: this.props.name });

        }

    }

    render () {


        // itemToString extracts the name key from the hash 

        return (
            
            <SelectRemote 
                name = { this.state.inputName }
                label = { this.props.label }
                remoteUrl = {this.props.remoteUrl}
                formatValueToDisplay = { this.formatValueToDisplay }
                renderSuggestion = { this.renderSuggestion }
                onRemoteItemChange = { this.handleRemoteItemChange } 
                inputId = "user_select"
                canCreateNew = { true }
                
            />
        )

    }
}


SelectUser.propTypes = {
    options: PropTypes.array,
    name: PropTypes.string,
    label: PropTypes.string,
    inputOnChange: PropTypes.func,
    formatValueToDisplay: PropTypes.func,
    onSelectUser: PropTypes.func,
}


SelectUser.defaultProps = {
    options: [],
    name: "",
    label: "",
    onSelectUser: undefined
}




export default SelectUser