import React from "react"
import PropTypes from "prop-types"

// custom reusable Material-Table template 
import Datatable from "components/Datatable"

// for the toolbar new button 
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';

class DatatableRegions extends React.Component {
  render () {
    return (
      <div style={{ maxWidth: "100%" }}>
        <Datatable
          title= {this.props.title}
          columns= {[
            { title: 'ID', field: 'properties.id', filtering: false },
            { title: 'Name', field: 'properties.name', filtering: false },
            { 
              title: 'Country', 
              field: 'properties.country_id' ,
              lookup: JSON.parse( this.props.countryFilter )
            }
          ]}
          remoteUrl="/api/regions"
          toolbarContent={
            <div style={{padding: '0px 10px'}}>
              <a
                href="/regions/new"
                >
                <Chip 
                  icon={<Icon>add_circle</Icon>}
                  label="New Region"
                  onClick={ true }
                  variant="outlined"
                  color="primary"
                />
              </a>
              
            </div>
          }
          
        />
      </div>
    );
  }
}

DatatableRegions.propTypes = {
  title: PropTypes.string,
  countryFilter: PropTypes.string
};

export default DatatableRegions
