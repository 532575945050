import React, { useState } from "react";
import PropTypes from "prop-types"

import {render} from 'react-dom'
import Downshift from 'downshift'
import Paper from '@material-ui/core/Paper';

import MenuItem from '@material-ui/core/MenuItem';

// for row Avatar 
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

import clsx from 'clsx';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';

import { createMuiTheme } from "@material-ui/core";
import blue from "@material-ui/core/colors/blue";
import { ThemeProvider } from "@material-ui/styles";

import { Input } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';


// Date.now();

const materialTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: blue.A200,
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          //backgroundColor: blue.A200,
          //color: "white",
        },
      },
      MuiPickersDay: {
        day: {
          // color: blue.A700,
        },
        daySelected: {
          backgroundColor: blue["400"],
        },
        dayDisabled: {
          // color: blue["100"],
        },
        current: {
          color: blue["900"],
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: blue["400"],
        },
      },
    },
  });

class OrganisationTagSelect extends React.Component {
    state = {
        fetchedData: this.props.fetchedData,
        searchCache: this.props.searchCache,
        selectedItems: this.props.selectedItems,
        selectedItemIds: [],
        inputValue: ""
    };

    setFetchedData(status) {
        this.setState({ fetchedData: status });
    }

    setSearchCache(status) {
        this.setState({ searchCache: status });
    }

    setSelectedItems(status) {
        this.setState({ selectedItems: status });
    }

    setSelectedItemIds(status) {
        this.setState({ selectedItemIds: status });
    }

    setStateWithIdKey(event) {
        this.setState({ [event.target.name]: event.target.value });
        console.log("state:", {[event.target.name]: event.target.value })
        // alternatively using template strings for strings
        // this.setState({ [`key${event.target.id}`]: event.target.value });
    }

    
    render () {

        // when user types in the search input we 
        // fetch the remote data 
        const inputOnChange = event => {
            
            fetchRemoteData(event.target.value.toLowerCase());
        }
        // we fetch the remote data and set to state. 
        const fetchRemoteData = searchInput => {
            // if the search term is null or less than 3 characters we 
            // return an empty array. NB Our ES search server is configured
            // for search terms > 3 characters
            if (searchInput === "" || searchInput.trim().length < 3 ) {
                this.setFetchedData([]);
            // if the search cache already contains our search term
            // we return the cached data
            } else if ( this.state.searchCache[searchInput] ) {
                this.setFetchedData( this.state.searchCache[searchInput] );
            // else we query the api and assign the fetched data to state and 
            // to a new search cache keyed to our search term
            } else {
                const apiUrl = `${this.props.remoteUrl}?q=${searchInput}`;
                fetch(apiUrl)
                    .then(res => res.json())
                    .then(json => {
                        if (searchInput) {
                            // TODO 
                            // we check that the returned json 
                            // has a data node. Our server side API 
                            // does not handle empty results very well 
                            // and returns json with the incorrect root node.
                            if ("data" in json) {
                                this.setFetchedData(json.data);
                                const newResults = { [searchInput]: json.data };
                                const newSearchCache = Object.assign({}, this.state.searchCache, newResults);
                                this.setSearchCache(newSearchCache);
                            } else {
                                this.setFetchedData([]);
                            }

                            
                        }
                    })
                    .catch(error => console.log(error));
            }
        };

        // when user selects an item from the dropdown
        const handleChange = selection => {
//            alert(
//                selection ? `You selected ${selection.id}` : 'Selection Cleared'
//            )
            // we get the existing selections from state... 
            let newSelectedItem = [...this.state.selectedItems];
            // ...check that they DO NOT alredy contain the new selection... 
            if (newSelectedItem.indexOf(selection) === -1) {
                //  ...merge the new selection...
                newSelectedItem = [...newSelectedItem, selection];
            }
            // ... then update the state.
            this.setSelectedItems(newSelectedItem);

            if ( "status" in selection && selection.status == '_new' ) {
                this.setState({ 
                    [`prerep[organisations_attributes][${selection.id}][name]`]: selection.name 
                });
                this.setState({ 
                    [`prerep[organisations_attributes][${selection.id}][type]`]: "Operator" 
                });
            }


//            // we modify the hidden select field by adding a new option tag for the 
//            // selected item. This is what gets submitted when the form submits.  
//            let mySelect = document.getElementById("prerep_organisation_ids");
//            for (var item of newSelectedItem) {
//                let input = document.createElement("input");
//                input.type = "text"
//
//                const value = ( "status" in item && item.status == '_new' )
//                    ? item.name
//                    : item.id
//
//                if ( "status" in item && item.status == '_new' ) {
//                    input.name = `prerep[organisations_attributes][${item.id}][name]`
//                    
//                } else {
//                    input.name = "prerep[organisation_ids][]"
//                }
//
//                input.value = value
//                
//                //option.value = item.id
//                //option.text = item.name
//                //option.selected = "selected"
//                // we check whether an option already exists for this item
//                // and create it if not
//                if ( mySelect.querySelectorAll(`input[value="${value}"]`).length < 1 ) {
//                    mySelect.append(input)
//                    if ( "status" in item && item.status == '_new' ) {
//                        let type = document.createElement("input");
//                        type.type = "text"
//                        type.name = `prerep[organisations_attributes][${item.id}][type]`
//                        type.value = "Operator"
//
//
//                        let country = document.createElement("input");
//                        country.type = "text"
//                        country.name = `prerep[organisations_attributes][${item.id}][country_id]`
//                        country.value = "4"
//                        mySelect.append(country)
//                    }
//                }
//            }


            
            
            // TODO select.value is not using this? 
            const ids = newSelectedItem.map(value => value.id) //.toString()                
            this.setSelectedItemIds( ids );
        
        }

        // when user clicks delete on a chip we remove the item from the 
        // selectedItems in state, and remove the corresponding option tag
        // from the hidden select field
        const handleDelete = item => () => {
            const newSelectedItem = [...this.state.selectedItems];
            newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
            this.setSelectedItems(newSelectedItem);
            console.log("delete", item)

            let mySelect = document.getElementById("prerep_organisation_ids");
            let options = mySelect.querySelectorAll(`option[value="${item.id}"]`)
            for (var option of options) {
                mySelect.removeChild(option)
            }

            

        };

        const handleEditNew = event => {
            console.log("event:", event )
            console.log("event:", event )
            this.setStateWithIdKey(event);
        }

        const handleKeyDown = event => () => {
            console.log("keydown")
//            if (selectedItem.length && !inputValue.length && event.key === 'Backspace') {
//              setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
//            }
        }

        // we calculate the index for the "create new" selection
        const newItemLength = item => {
            this.state.fetchedData.length +1 
        }

        


       

        
            

        return (
            <React.Fragment>
                <ThemeProvider theme={materialTheme}>
                <Downshift
                    id= "organisations_select"
                    onChange={handleChange}
                    
                    // we don't handle the selected items in the search input
                    // so this is always ''
                    itemToString={item => (item ? '' : '')}
                    selectedItem={this.state.selectedItems}
                >
                    {({
                        getInputProps,
                        getItemProps,
                        getLabelProps,
                        getMenuProps,
                        isOpen,
                        inputValue,
                        highlightedIndex,
                        selectedItem,
                    }) => (
                    <div>
                        
                        { 
                            // we build a hidden select field that will recieve options from 
                            // #handleChange and submit on firm submit
                        }

                        

                        <TextField {...getInputProps({
                            label: this.props.label,  
                            fullWidth: true,                          
                            InputLabelProps: {...getLabelProps({ shrink: true })},
                            onChange: inputOnChange,
                            autoComplete: "off",

                            InputProps: {
                                startAdornment: selectedItem.map(item => {
                                    return ( "status" in item && item.status == '_new' )
                                        ? null
                                        : <Chip
                                            key={item.id}
                                            tabIndex={-1}
                                            label={item.name}
                                            onDelete={handleDelete(item)}
                                            avatar={
                                                <Avatar 
                                                    alt={item.name}
                                                    src={item.avatar}
                                                    style={{  margin: 0, backgroundColor:"#134886", color: "#ffffff"}}
                                                    >
                                                    {item.name.match(/\b(\w)/g).join('').toUpperCase()}
                                                </Avatar>
                                            }
                                        />
                                }),
                            }
                        })} />



                        
                        
                        
                        { 
                            // when the drop down is open we render the items
                        }
                        { isOpen ? (
                                
                            <Paper {...getMenuProps()} square>
  
                                {   this.state.fetchedData

                                    .map((item, index) => (
                                                                                
                                        <MenuItem 
                                            {...getItemProps({
                                                key: item.id,
                                                index,
                                                item,
                                                
                                            })}
                                            component="div"
                                            className= "row"
                                        >
                                            
                                                <div className="col-2">
                                                    <Avatar 
                                                        alt={item.name}
                                                        src={item.avatar}
                                                        style={{  margin: 0, backgroundColor:"#134886"}}
                                                        >
                                                        {item.name.match(/\b(\w)/g).join('').toUpperCase()}
                                                        
                                                    </Avatar>
                                                </div>
                                                <div className="col-10">
                                                    <p className="mb-0"><b>{item.name}</b></p>
                                                    <div className="row">
                                                        <div className="col d-flex justify-content-between">
                                                            <p className="mb-0 text-left">
                                                                <span className={`flag-icon flag-icon-${item.country_alpha.toLowerCase()}`} />
                                                                <small> {item.country}</small>
                                                            </p>
                                                            <p className="mb-0 text-right">
                                                                { [...Array(item.rating)].map((e, i) => <i className="fas fa-star" key={i}></i>) }
                                                                { [...Array(5-item.rating)].map((e, i) => <i className="far fa-star" key={i}></i>) }
                                                            </p>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            
                                        </MenuItem>
                                    )) 
                                }
                                { 
                                    // we add an additional menu item for the "create new" option
                                }
                                <MenuItem 
                                    {...getItemProps({
                                        key: inputValue,
                                        index: newItemLength,
                                        item: {value: inputValue, status:'_new', id: Date.now(), name: inputValue}
                


                                    })}
                                    component="div"
                                    className= "row"
                                >
                                    <div className="col-2">
                                        <i><small>Add new:</small></i>
                                    </div> 
                                    <div className="col-2">
                                        <p className="mb-0"><b>{inputValue}</b></p>
                                    </div>
                                </MenuItem>

                            </Paper>
                        ) : null }

                        { this.state.selectedItems.map(item => {
                            console.log("item:", item)
                            if ( "status" in item && item.status == '_new' ) {
                                return ( <div className="form-group new-organisation">
                                    <InputLabel 
                                        htmlFor={`prerep_organisations_attributes_${item.id}_name`}
                                        className="sr-only"
                                        >
                                            Name
                                    </InputLabel>
                                    <Input
                                        type="text"
                                        id = {`prerep_organisations_attributes_${item.id}_name`}
                                        name = {`prerep[organisations_attributes][${item.id}][name]`}
                                        className = { "prerep-organisation-name" }
                                        value = { this.state[`prerep[organisations_attributes][${item.id}][name]`] }
                                        onChange = {handleEditNew}
                                    />
                                    <InputLabel 
                                        htmlFor={`prerep_organisations_attributes_${item.id}_type`}
                                        className="sr-only"
                                        >
                                            Kind
                                    </InputLabel>
                                    <Select
                                        value = { this.state[`prerep[organisations_attributes][${item.id}][type]`] }
                                        onChange = {handleEditNew}
                                        inputProps={{
                                            name: `prerep[organisations_attributes][${item.id}][type]`,
                                            className: "prerep-organisation-type",
                                            id: `prerep_organisations_attributes_${item.id}_type`,
                                        }}
                                        MenuProps={{
                                            className: "organisation-type-select-menu"
                                        }}
                                    >
                                        <MenuItem value="Operator">Operator</MenuItem>
                                        <MenuItem value="University">University</MenuItem>
                                    </Select> 
                                    <InputLabel 
                                        htmlFor={`prerep_organisations_attributes_${item.id}_country_id`}
                                        className="sr-only"
                                        >
                                            Country
                                    </InputLabel>
                                    <Input
                                        type="hidden"
                                        id = {`prerep_organisations_attributes_${item.id}_country_id`}
                                        name = {`prerep[organisations_attributes][${item.id}][country_id]`}
                                        value = { this.props.countryId }
                                    />
                                </div> )
                            } else {
                                return ( <div>
                                    <Input
                                        type="hidden"
                                        name = "prerep[organisation_ids][]"
                                        value = { item.id }
                                    />
                                </div> )
                            }
                        })}
                        
                    </div>
                    )}
                </Downshift>
                </ThemeProvider>
            </React.Fragment>
        )
    }
}

OrganisationTagSelect.propTypes = {
    fetchedData: PropTypes.array,
    searchCache: PropTypes.array,
    selectedItems: PropTypes.array,
    remoteUrl: PropTypes.string,
    label: PropTypes.string,
    countryId: PropTypes.number
};

OrganisationTagSelect.defaultProps = {
    fetchedData: [],
    searchCache: [],
    selectedItems: [],
    remoteUrl: "http://localhost:3000/api/organisations",
    label: "Default label",
    countryId: undefined
}

export default OrganisationTagSelect