import React from "react"
import PropTypes from "prop-types"
class Test extends React.Component {
  render () {
    return (
      <React.Fragment>
        Name: {this.props.name}
        Data: {this.props.data}
      </React.Fragment>
    );
  }
}

Test.propTypes = {
  name: PropTypes.string,
  data: PropTypes.array
};
export default Test
