
export class CropperInit {

    constructor(image,attName,options) {
        this.image          = image
        this.cropOptions    = options
        this.attName        = attName
    }

    init() {
        const image     = this.image
        const attName   = this.attName
        const options   = this.cropOptions
        var defaults  = {
            viewMode: 2,
            aspectRatio: 1,
            minCropBoxWidth: 50,
            minCropBoxHeight: 50,
            rotatable: false,
            scalable: false,
            zoomable: false,
            toggleDragModeOnDblclick: false,
            responsive: true,
            data: {
                x: 5,
                y: 5,
                width: 20,
                height: 20,
            },
            ready() {
                console.log("READY")
                console.log(
                    document.querySelector(`#${attName}_crop_x`).value
                )
            },
            crop(event) {
                document.querySelector(`#${attName}_crop_x`).value = event.detail.x
                document.querySelector(`#${attName}_crop_y`).value = event.detail.y
                document.querySelector(`#${attName}_crop_w`).value = event.detail.width
                document.querySelector(`#${attName}_crop_h`).value = event.detail.height
            }
        }

        // we can override the cropper default options by merging 
        // the cropOptions data attributes
        if (image.hasAttribute("data-crop-options")) {
            var newOptions = JSON.parse(image.dataset.cropOptions)
            console.log("newOptions")
            console.log(newOptions)
            defaults = Object.assign({}, defaults, newOptions)
            console.log(options)
        }


        var mergedOptions = Object.assign({}, defaults, options)
        console.log("mergedOptions")
        console.log(mergedOptions)

        new Cropper(image, mergedOptions)
    }


}
