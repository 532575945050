import React from "react"
import PropTypes from "prop-types"

// custom reusable Material-Table template 
import Datatable from "components/Datatable"

// for the toolbar new button 
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';

// for row Avatar 
import Avatar from '@material-ui/core/Avatar';

class DatatableUsers extends React.Component {
  render () {
    return (
      <Datatable
        title= {this.props.title}
        columns= {[
          { title: 'ID', field: 'id', filtering: false },
          {
            title: 'Avatar',
            field: 'avatar',
            filtering: false,
            render: rowData => (
              <Avatar 
                alt={rowData.name}
                src={rowData.avatar}
                style={{  margin: 0, backgroundColor:"#134886"}}
              >
                {rowData.name.match(/\b(\w)/g).join('').toUpperCase()}
              </Avatar>
            ),
          },
          { title: 'Name', field: 'name', filtering: false },
          { title: 'Email', field: 'email', filtering: false },
          { title: 'Roles', field: 'roles', filtering: false }
        ]}
        remoteUrl="/api/users"
        baseUrl="/users/"
      />

    );
  }
}

DatatableUsers.propTypes = {
  title: PropTypes.string
};

export default DatatableUsers
