import React, { useState } from "react";
import PropTypes from "prop-types"

import blue from "@material-ui/core/colors/blue";

import { makeStyles } from '@material-ui/core/styles';
import deburr from 'lodash/deburr';

import Downshift from 'downshift'
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

import { Input } from '@material-ui/core';


const suggestionsx = [
    { label: 'Afghanistan' },
    { label: 'Aland Islands' },
    { label: 'Albania' },
    { label: 'Algeria' },
    { label: 'American Samoa' },
    { label: 'Andorra' },
    { label: 'Angola' },
    { label: 'Anguilla' },
    { label: 'Antarctica' },
    { label: 'Antigua and Barbuda' },
    { label: 'Argentina' },
    { label: 'Armenia' },
    { label: 'Aruba' },
    { label: 'Australia' },
    { label: 'Austria' },
    { label: 'Azerbaijan' },
    { label: 'Bahamas' },
    { label: 'Bahrain' },
    { label: 'Bangladesh' },
    { label: 'Barbados' },
    { label: 'Belarus' },
    { label: 'Belgium' },
    { label: 'Belize' },
    { label: 'Benin' },
    { label: 'Bermuda' },
    { label: 'Bhutan' },
    { label: 'Bolivia, Plurinational State of' },
    { label: 'Bonaire, Sint Eustatius and Saba' },
    { label: 'Bosnia and Herzegovina' },
    { label: 'Botswana' },
    { label: 'Bouvet Island' },
    { label: 'Brazil' },
    { label: 'British Indian Ocean Territory' },
    { label: 'Brunei Darussalam' },
];





const classes = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      height: 250,
    },
    container: {
      flexGrow: 1,
      position: 'relative',
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    inputRoot: {
      flexWrap: 'wrap',
    },
    inputInput: {
      width: 'auto',
      flexGrow: 1,
    },
    divider: {
      height: theme.spacing(2),
    },
}));
  

  


class SurveyLocationSelect extends React.Component {
    state = {
        fetchedData: this.props.fetchedData,
        searchCache: this.props.searchCache,
        selectedItem: this.props.selectedItem,
        inputValue: ""
    };

    


    render () {

        const suggestions = this.props.suggestions


        // itemToString extracts the name key from the hash 

        return (
            
            <Downshift id="downshift-simple" itemToString={item => (item ? item.name : '')}>
                {({
                    getInputProps,
                    getItemProps,
                    getLabelProps,
                    getMenuProps,
                    highlightedIndex,
                    inputValue,
                    isOpen,
                    selectedItem,
                }) => {
                    const { onBlur, onFocus, ...inputProps } = getInputProps({
                        //placeholder: 'Search for a country (start with a)',
                    });
    
                    return (
                        <div className={classes.container}>
                            {renderField({ 
                                name: this.props.inputName,
                                // selectedItem is [] initially, the absence of id key 
                                // raises an error, so we check it exists 
                                value: selectedItem ? selectedItem.id : "" 
                            })}
                            {renderInput({
                                fullWidth: true,
                                
                                classes,
                                label: 'Country',
                                InputLabelProps: getLabelProps({ shrink: true }),
                                InputProps: { onBlur, onFocus },
                                inputProps,
                            })}
        
                            <div {...getMenuProps()}>
                                {isOpen ? (
                                    <Paper className={classes.paper} square>
                                        {getSuggestions(inputValue, suggestions).map((suggestion, index) =>
                                            renderSuggestion({
                                                suggestion,
                                                index,
                                                itemProps: getItemProps({ item: suggestion }),
                                                highlightedIndex,
                                                selectedItem,
                                            }),
                                        )}
                                    </Paper>
                                ) : null}
                            </div>
                        </div>
                    );
                }}
            </Downshift>
        )

    }
}

function renderField(inputProps) {
    
    return (
        <Input
            type="hidden"
            { ...inputProps }
        />
    )
}

function renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;
  
    return (
        <TextField
            InputProps={{
                inputRef: ref,
                autoComplete: "off",
                classes: {
                    root: classes.inputRoot,
                    input: classes.inputInput,
                },
                ...InputProps,
            }}
            {...other}
        />
    );
}
  

function getSuggestions(value, suggestions, { showEmpty = false } = {}) {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    console.log("suggestions",suggestions)
  
    return inputLength === 0 && !showEmpty
        ? []
        : suggestions.filter(suggestion => {
          const keep =
            count < 5 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;
  
          if (keep) {
            count += 1;
          }
  
          return keep;
        });
}

function renderSuggestion(suggestionProps) {
    const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
    const isHighlighted = highlightedIndex === index;

    // if we try to access the selectedItem.id directly it raises an error 
    // on page load because the id does not exist. selectedItem = []
    // so we check if selectedItem exists
    const isSelected = (selectedItem && selectedItem.id || '').indexOf(suggestion.id) > -1;
  
    return (
        <MenuItem
            {...itemProps}
            key={suggestion.id}
            selected={isHighlighted}
            component="div"
            style={{
                fontWeight: isSelected ? 500 : 400,
            }}
        >
            {suggestion.name}
        </MenuItem>
    );
}

renderSuggestion.propTypes = {
    highlightedIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]).isRequired,
    index: PropTypes.number.isRequired,
    itemProps: PropTypes.object.isRequired,
    selectedItem: PropTypes.string.isRequired,
    suggestion: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
};

SurveyLocationSelect.propTypes = {
    divesitesRemoteUrl:PropTypes.string,
    regionsRemoteUrl: PropTypes.string,
    countriesRemoteUrl: PropTypes.string,
    suggestions: PropTypes.array,
    inputName: PropTypes.string,
}


SurveyLocationSelect.defaultProps = {
    divesitesRemoteUrl: "http.divesite.api",
    regionsRemoteUrl: "http.divesite.api",
    countriesRemoteUrl: "http.divesite.api",
    suggestions: [],
    inputName: "",
    selectedItem: [{id: ""}]
}




export default SurveyLocationSelect