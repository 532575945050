import React, { useState } from "react";
import PropTypes from "prop-types"

import blue from "@material-ui/core/colors/blue";

import { makeStyles } from '@material-ui/core/styles';
import deburr from 'lodash/deburr';

import Downshift from 'downshift'
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

import { Input } from '@material-ui/core';




const classes = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      height: 250,
    },
    container: {
      flexGrow: 1,
      position: 'relative',
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    inputRoot: {
      flexWrap: 'wrap',
    },
    inputInput: {
      width: 'auto',
      flexGrow: 1,
    },
    divider: {
      height: theme.spacing(2),
    },
}));
  

  


class SelectBasic extends React.Component {
    state = {
        fetchedData: this.props.fetchedData,
        searchCache: this.props.searchCache,
        selectedItem: this.props.selectedItem,
        inputValue: ""
    };


    renderSuggestion = (suggestionProps) => {
        if (typeof this.props.renderSuggestion === 'function') {
            return this.props.renderSuggestion(suggestionProps);
        } else {
            const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
            const isHighlighted = highlightedIndex === index;
            
            // if we try to access the selectedItem.id directly it raises an error 
            // on page load because the id does not exist. selectedItem = []
            // so we check if selectedItem exists
            const selectedItemId = selectedItem ? selectedItem.id : ''
            const suggestionId = suggestion ? suggestion.id : ''
            
            const isSelected = selectedItemId === suggestionId //(selectedItemId).indexOf(suggestion ) > -1;
        
            return (
                <MenuItem
                    {...itemProps}
                    key={suggestion.id}
                    selected={isHighlighted}
                    component="div"
                    style={{
                        fontWeight: isSelected ? 500 : 400,
                    }}
                >
                    {suggestion.name}
                </MenuItem>
            );
        }
        
    }

    getSuggestions = (value, suggestions, { showEmpty = false } = {}) => {

        if (typeof this.props.getSuggestions === 'function') {
            return this.props.getSuggestions(value, suggestions);
        } else {



            const inputValue = deburr(value.trim()).toLowerCase();
            const inputLength = inputValue.length;
            let count = 0;          
        
            return inputLength === 0 && !showEmpty
                ? []
                : suggestions.filter(suggestion => {
                const keep =
                    count < 5 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;
        
                if (keep) {
                    count += 1;
                }
        
                return keep;
                });
        }
    }
    
    

 


    render () {

        
        const suggestions = this.props.options

        const inputOnChange = event => {
            if (typeof this.props.inputOnChange === 'function') {
                this.props.inputOnChange(event);
            } else {
                null
            }
        }

        const formatValueToDisplay = item => {
            if (typeof this.props.formatValueToDisplay === 'function') {
                return this.props.formatValueToDisplay(item);
            } else { 
                return item 
                    ? item.name
                    : "" 
            }
            
        }

        // we calculate the index for the "create new" selection
        const newItemLength = item => {
            this.state.options.length +1 
        }

        // when user selects an item from the dropdown
        const handleChange = selection => {
            if (typeof this.props.onItemChange === 'function') {
                return this.props.onItemChange(selection);
            } else {
                null
            }
        }

        

        console.log("ghghhgh:", this.props.selectedItem)

        // itemToString extracts the name key from the hash 

        return (
            
            <Downshift 
                id = { this.props.inputId } 
                itemToString= { item => formatValueToDisplay(item) }
                onChange = {handleChange}
                initialInputValue = {this.props.selectedItem ? ( this.props.selectedItem.name || "" ) : "" }
                initialSelectedItem = {this.props.selectedItem}
            >
                {({
                    getInputProps,
                    getItemProps,
                    getLabelProps,
                    getMenuProps,
                    highlightedIndex,
                    inputValue,
                    isOpen,
                    selectedItem,
                }) => {
                    const { onBlur, onFocus, ...inputProps } = getInputProps({
                        //placeholder: 'Search for a country (start with a)',
                    });
    
                    return (
                        <div className={classes.container}>
                            { console.log("selectedItem:", selectedItem) }
                            <Input
                                name= { this.props.name }
                                type="hidden"
                                // selectedItem is [] initially, the absence of id key 
                                // raises an error, so we check it exists 
                                // TODO we have to provide an OR here to accommodate the GEOJSON / JSONAPI differences
                                value= { selectedItem ? ( selectedItem.id || selectedItem.properties.id ) : "" }
                            />
                            

                            <TextField {...getInputProps({
                                error: this.props.error,
                                fullWidth: true,
                                label: this.props.label,  
                                onChange: inputOnChange,  
                                autocomplete: "off",  
                                helperText: this.props.helperText,
                                InputLabelProps: {...getLabelProps({ shrink: true, className: this.props.labelClassName })}
                            })} />
                            
            
        
                            <div {...getMenuProps()}>
                                {isOpen ? (
                                    <Paper className={classes.paper} square>
                                        {this.getSuggestions(inputValue, suggestions).map((suggestion, index) =>
                                            this.renderSuggestion({
                                                suggestion,
                                                index,
                                                itemProps: getItemProps({ item: suggestion }),
                                                highlightedIndex,
                                                selectedItem,
                                            }),
                                        )}
                                        
                                        { this.props.canCreateNew ? (
                                            <MenuItem 
                                                {...getItemProps({
                                                    key: inputValue,
                                                    index: newItemLength,
                                                    item: {value: inputValue, status:'_new', id: inputValue, name: inputValue}
                                                })}
                                                component="div"
                                                className= "row"
                                            >
                                                <div className="col-2">
                                                    <i><small>Add new:</small></i>
                                                </div> 
                                                <div className="col-2">
                                                    <p className="mb-0"><b>{inputValue}</b></p>
                                                </div>
                                            </MenuItem>
                                        ) : null }
                                    </Paper>
                                ) : null}
                            </div>
                        </div>
                    );
                }}
            </Downshift>
        )

    }
}







//renderSuggestion.propTypes = {
//    highlightedIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]).isRequired,
//    index: PropTypes.number.isRequired,
//    itemProps: PropTypes.object.isRequired,
//    selectedItem: PropTypes.string.isRequired,
//    suggestion: PropTypes.shape({
//        id: PropTypes.number.isRequired,
//        name: PropTypes.string.isRequired,
//    }).isRequired,
//};

SelectBasic.propTypes = {
    divesitesRemoteUrl:PropTypes.string,
    regionsRemoteUrl: PropTypes.string,
    countriesRemoteUrl: PropTypes.string,
    options: PropTypes.array,
    name: PropTypes.string,
    label: PropTypes.string,
    inputOnChange: PropTypes.func,
    formatValueToDisplay: PropTypes.func,
    canCreateNew: PropTypes.bool,
    formatValueToDisplay: PropTypes.func,
    onItemChange: PropTypes.func,
    renderSuggestion: PropTypes.func,
    helperText: PropTypes.string,
    getSuggestions: PropTypes.func,
    inputId: PropTypes.string,
    error: PropTypes.bool,
    labelClassName: PropTypes.string,
}


SelectBasic.defaultProps = {
    divesitesRemoteUrl: "http.divesite.api",
    regionsRemoteUrl: "http.divesite.api",
    countriesRemoteUrl: "http.divesite.api",
    options: [],
    name: "",
    label: "",
    canCreateNew: false,
    formatValueToDisplay: null,
    onItemChange: null,
    helperText: "",
    renderSuggestion: null,
    getSuggestions: null,
    inputId: "downshift-simple" ,
    error: false,
    labelClassName: undefined
}




export default SelectBasic