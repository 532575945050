import React, { useState } from "react";
import PropTypes from "prop-types"

import SelectRemote from "components/SelectRemote"

import MenuItem from '@material-ui/core/MenuItem';



class SelectDivesite extends React.Component {
    state = {
        //fetchedData: this.props.fetchedData,
        //searchCache: this.props.searchCache,
        //selectedItem: this.props.selectedItem,
        //inputValue: "",
        isNew: false,
        inputName: this.props.name,
        helperText: this.props.helperText
    };

    
    

 


    



    formatValueToDisplay = (item) => {
        const isNew = ( item && 'status' in item && item.status === "_new")

        if ( item &&  isNew ) {
            return item.name
        } else if ( item ) {
            return `${item.properties.name}, ${item.properties.region_name}, ${item.properties.country_name}`
        } else {
            return ""
        }
    }


    // when user selects an item from the dropdown
    handleRemoteItemChange = (selection) => {

        const isNew = ('status' in selection && selection.status === "_new")

        this.setState({ isNew: isNew });

        // we run the parent function if defined
        if (typeof this.props.onSelectDivesite === 'function') {
            this.props.onSelectDivesite(selection);
        }
        
        if (isNew) {
            //this.setState({ inputName: "prerep[divesite_name]" }) 
            this.setState({ inputName: "prerep[divesite_attributes][name]" }) 
            this.setState({ helperText: "It looks like you're adding a new divesite. Add details about the region below." });
        } else {
            this.setState({ inputName: this.props.name });
            this.setState({ helperText: this.props.helperText });
        }

    }

    renderSuggestion = (suggestionProps) => {
        const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
        const isHighlighted = highlightedIndex === index;
        
        // if we try to access the selectedItem.id directly it raises an error 
        // on page load because the id does not exist. selectedItem = []
        // so we check if selectedItem exists
        const selectedItemId = selectedItem ? selectedItem.id : ''
        const suggestionId = suggestion ? suggestion.id : ''
        
        const isSelected = selectedItemId === suggestionId //(selectedItemId).indexOf(suggestion ) > -1;
      
        return (
            <MenuItem 
                className= "row"
                {...itemProps}
                key={suggestion.properties.id}
                selected={isHighlighted}
                component="div"
                style={{
                    fontWeight: isSelected ? 500 : 400,
                }}
            >

                <div className="col-2 text-center">
                    <span className={`flag-icon flag-icon-${suggestion.properties.country_alpha.toLowerCase()}`} />
                </div>
                <div className="col-10">
                    <p className="mb-0"><b>{suggestion.properties.name}</b></p>
                    <div className="row">
                        <div className="col d-flex justify-content-between">
                            <p className="mb-0 text-left">
                                <small>{suggestion.properties.region_name}, {suggestion.properties.country_name}</small>
                            </p>
                            <p className="mb-0 text-right">
                                { [...Array(suggestion.properties.rating)].map((e, i) => <i className="fas fa-star" key={i}></i>) }
                                { [...Array(5-suggestion.properties.rating)].map((e, i) => <i className="far fa-star" key={i}></i>) }
                            </p>
                        </div>
                    </div>
                    
                </div>

            </MenuItem>



        );
    }

    render () {


        // itemToString extracts the name key from the hash 

        return (
            
            <SelectRemote 
                name = { this.state.inputName }
                label = { this.props.label }
                remoteUrl = {this.props.remoteUrl}
                formatValueToDisplay = { this.formatValueToDisplay }
                canCreateNew = { true }
                onRemoteItemChange = { this.handleRemoteItemChange } 
                helperText = { this.state.helperText }
                renderSuggestion = { this.renderSuggestion }
                inputId = "divesite_select"
                error = { this.props.error }
                selectedItem = {this.props.selectedItem}
            />
        )

    }
}




SelectDivesite.propTypes = {
    remoteUrl: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    inputOnChange: PropTypes.func,
    formatValueToDisplay: PropTypes.func,
    onSelectDivesite: PropTypes.func,
    helperText: PropTypes.string,
    error: PropTypes.bool,
    selectedItem: PropTypes.object, 
}


SelectDivesite.defaultProps = {
    remoteUrl: "http.divesite.api",
    //options: [],
    name: "",
    label: "",
    onSelectDivesite: null,
    helperText: "",
    error: false,
    selectedItem: null 
}




export default SelectDivesite