import React from "react"
import PropTypes from "prop-types"

// custom reusable Material-Table template 
import Datatable from "components/Datatable"

// for the toolbar new button 
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';

class DatatableCountries extends React.Component {
  render () {
    return (
      <div style={{ maxWidth: "100%" }}>
        <Datatable
          title= {this.props.title}
          columns= {[
            { title: 'ID', field: 'properties.id', filtering: false },
            {
              title: 'Flag',
              field: 'properties.alpha',
              filtering: false,
              render: rowData => (
                <span 
                  className={`flag-icon flag-icon-${rowData.properties.alpha.toLowerCase()}`}
                ></span>
              ),
            },
            { title: 'Name', field: 'properties.name', filtering: false },
            { title: 'Alpha', field: 'properties.alpha', filtering: false },
            { title: 'Code', field: 'properties.code', filtering: false }
          ]}
          remoteUrl="/api/countries"
          toolbarContent={
            <div style={{padding: '0px 10px'}}>
              <a
                href="/countries/new"
                >
                <Chip 
                 icon={<Icon>add_circle</Icon>}
                 label="New Country"
                 onClick={ true }
                 variant="outlined"
                 color="primary"
                 />
              </a>
              
            </div>
          }
          
        />
      </div>
    );
  }
}


DatatableCountries.propTypes = {
  title: PropTypes.string
};

export default DatatableCountries
