import React, {Component} from 'react';

import PropTypes from "prop-types"
import {render} from 'react-dom';
// we have disabled mapbox-gl import and instead are using 
// the cdn version called in the html layout. import was raiing an error
//import mapboxgl from 'mapbox-gl'


mapboxgl.accessToken = 'pk.eyJ1IjoiYW5kcmV3aGFydmV5IiwiYSI6ImNpbHg4Z2k2MzA3eGh1OWtyYWppZnhsMW0ifQ.kTxftIcMzmTtjjzIn2qPog'

class Mapbox extends React.Component {
    map; 

    state = {
        lng: this.props.lng,
        lat: this.props.lat,
        zoom: this.props.zoom
    };

    componentDidMount() {
        const { lng, lat, zoom } = this.state;
        const {
            dragPan,
            scrollZoom,
            boxZoom,
            dragRotate,
            keyboard,
            doubleClickZoom,
            touchZoomRotate
        } = this.props
    
        this.map = new mapboxgl.Map({
            container: this.mapContainer,
            center: [lng, lat],
            zoom,
            renderWorldCopies: true,
            minZoom: 0,
            maxZoom: 10,
            style: 'mapbox://styles/andrewharvey/ciouyfj3i0028arm77c3watem',
            dragPan: dragPan,
            scrollZoom: scrollZoom,
            boxZoom: boxZoom,
            dragRotate: dragRotate,
            keyboard: keyboard,
            doubleClickZoom: doubleClickZoom,
            touchZoomRotate: touchZoomRotate,
      
        });

        const isInteractable = ( dragPan ||
            scrollZoom ||
            boxZoom ||
            dragRotate ||
            keyboard ||
            doubleClickZoom ||
            touchZoomRotate ||
            touchZoomRotate 
        )

        // if the map is not interactable we change to the mouse pointer
        // otherwise we keep the grab mouse cursour
        if ( !isInteractable ) {
            this.map.getCanvas().style.cursor = 'default'
        }

        // if touch control is enabled, we still disbale rotate
        // we never want these maps to rotate
//        if ( touchZoomRotate ) {
//            this.map.touchZoomRotate.disableRotation() 
//        }
    
//        this.map.on('move', () => {
//            const { lng, lat } = this.map.getCenter();
//    
//            this.setState({
//                lng: lng.toFixed(4),
//                lat: lat.toFixed(4),
//                zoom: this.map.getZoom().toFixed(2)
//            });
//        });
    }

    componentDidUpdate() {
        this.recenterMap();
    }


    recenterMap() {
        const { lat, lng, zoom } = this.props;
        console.log("lat:", lat)
        console.log("lng:", lng)
        this.map.flyTo({
            center: [lat,lng],
            zoom: zoom
        })
        
    }
        
    

    
    render () {

        const { lng, lat, zoom } = this.state;
        const height = this.props.height
        console.log("height:", `${height}px`)

        return (
            <div>
                { (false) 
                    ? (
                        <div className="inline-block absolute top left mt12 ml12 bg-darken75 color-white z1 py6 px12 round-full txt-s txt-bold">
                            <div>{`Longitude: ${lng} Latitude: ${lat} Zoom: ${zoom}`}</div>
                        </div>
                    )
                    : null } 
                
                <div style={{"height" : `${height}px` }} ref={el => this.mapContainer = el} className="absolute top right left bottom" />
            </div>
        )
    }

}

Mapbox.propTypes = {
    
    //height: PropTypes.number
    height: PropTypes.number,
    lat: PropTypes.number,
    lng: PropTypes.number,
    zoom: PropTypes.number,

    dragPan: PropTypes.bool,
    scrollZoom: PropTypes.bool,
    boxZoom: PropTypes.bool,
    dragRotate: PropTypes.bool,
    keyboard: PropTypes.bool,
    doubleClickZoom: PropTypes.bool,
    touchZoomRotate: PropTypes.bool,


}


Mapbox.defaultProps = {
    height: 200,
    lat: 0,
    lng: 0,
    zoom: 0.1,

    dragPan: false,
    scrollZoom: false,
    boxZoom: false,
    dragRotate: false,
    keyboard: false,
    doubleClickZoom: false,
    touchZoomRotate: false,
    
}






export default Mapbox
