// import React from "react"
import PropTypes from "prop-types"
// //import React, { Fragment, useState } from "react";
//import DateFnsUtils from '@date-io/date-fns';
//import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";


import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
  DatePicker,
  TimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import { Button, makeStyles } from "@material-ui/core";

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';



const theme = createMuiTheme({
    palette: {
      primary: blue,
      secondary: blue,
    },
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: blue.A200,
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          //backgroundColor: blue.A200,
          //color: "white",
        },
      },
      MuiPickersDay: {
        day: {
          // color: blue.A700,
        },
        daySelected: {
          backgroundColor: blue["400"],
        },
        dayDisabled: {
          // color: blue["100"],
        },
        current: {
          color: blue["900"],
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: blue["400"],
        },
      },
    },
  });


// 
class DatePickerInput extends React.Component {
    state = {
        theDate: this.props.value,
        isOpen: false
    };

    handleDateChange(newDate) {   
        this.setState({ theDate: newDate });
    }
    setIsOpen(status) {
        this.setState({ isOpen: status });
    }
    
    render () {
        //const isOpen = this.props.isOpen
        //const isOpen = useState(false)
        //const setIsOpen = useState(false)

        // if user is not on a touch device, we show the datapicker
        // otherwise we show native controls
        if (!Modernizr.touch) {
            console.log("message:", this.props.errorMessage )
            console.log("truthy:", ( this.props.errorMessage ? true : false ) )

            return (
                
              <React.Fragment>
                <MuiThemeProvider theme={theme}>

                
                <div style={{ maxWidth: "100%" }}>
                

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>

                      <KeyboardDatePicker
                          //clearable
                          error={this.props.errorMessage ? true : false }
                          variant="inline"
                          label="Date"
                          fullWidth= {true}
                          open={this.state.isOpen}
                          onOpen={() => this.setIsOpen(true)}
                          onClose={() => this.setIsOpen(false)}
                          //onFocus={() => this.setIsOpen(true)}
                          //onBlur={() => this.setIsOpen(false)}
                          id={`${this.props.objectName}_${this.props.name}`}
                          //classes={{input: "form-control string optional datepicker flatpickr-input"}}
                          name={`${this.props.objectName}[${this.props.name}]`}
                          helperText={this.props.errorMessage}
                          value={this.state.theDate}
                          onChange={selection => this.handleDateChange(selection)}
                          disableFuture={true}
                          disablePast={false}
                          autoOk= {true}

                          //mask={false}
                          //refuse={null}
                          //inputValue={(new Date()).toDateString()}
                          //placeholder="10/10/2018"
                          //minDate={new Date()}
                          format="yyyy/MM/dd"
                          //views={["year", "date", "month"]}
                      />

                    </MuiPickersUtilsProvider>
                
                
                  </div>
                </MuiThemeProvider>
              </React.Fragment>
            );

           
        } else {
            return (
                <React.Fragment>
                    <FormControl className="form-control string optional datepicker flatpickr-input">
                        <InputLabel htmlFor={`${this.props.objectName}_${this.props.name}`}>Date</InputLabel>
                        <Input 
                          id={`${this.props.objectName}_${this.props.name}`} 
                          name={`${this.props.objectName}[${this.props.name}]`}
                          value={this.state.theDate} 
                          type="date" />
                    </FormControl>
                </React.Fragment>
            )
        }
    }
}

DatePickerInput.propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.bool,
    errorMessage: PropTypes.string
}

DatePickerInput.defaultProps = {
    isOpen: false,
    setIsOpen: false
}

export default DatePickerInput
