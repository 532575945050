import React, { useState } from "react";
import PropTypes from "prop-types"

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

import Link from '@material-ui/core/Link';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Button from '@material-ui/core/Button';

import SelectRemote from "components/SelectRemote"
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { DeleteForever } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';

import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';



const theme = createMuiTheme({
    palette: {
      primary: blue,
      secondary: blue,
    },
});

class LogForm extends React.Component {

    emptySighting =  {
        id: '',
        species_id: '',
        species: '',
        user_id: this.props.userId,
        count: '',
        errors: {},
        _destroy: false
    }

    buildInitialObject() {
        const obj = JSON.parse( this.props.prerep )

        obj.sightings_attributes = obj.sightings_attributes || []
        obj.observations_attributes = obj.observations_attributes || []
        obj.satisfactions_attributes = obj.satisfactions_attributes || []
        
        if ( obj.sightings.length > 0 ) {
            obj.sightings.map( (sighting, i) => {
                if (sighting.user_id == this.props.userId) {
                    obj.sightings_attributes.push( sighting )
                    
                }
            })
        }

        if ( obj.observations.length > 0 ) {
            obj.observations.map( (obs, i) => {
                const isNew = obs.id == null
                const isNotPendingUpdate = obj.observations_attributes.filter(newObs => newObs.id == obs.id).length < 1
                const isOwnedByCurrentUser = obs.user_id === this.props.userId
                if ( ( isNew || isNotPendingUpdate) && isOwnedByCurrentUser ) {
                    obj.observations_attributes.push( obs )
                }    
            })
        }

        if ( obj.satisfactions.length > 0 ) {
            obj.satisfactions.map( (sat, i) => {
                const isNew = sat.id == null
                const isNotPendingUpdate = obj.satisfactions_attributes.filter(newSat => newSat.id == sat.id).length < 1
                const isOwnedByCurrentUser = sat.user_id === this.props.userId
                if ( ( isNew || isNotPendingUpdate) && isOwnedByCurrentUser ) {
                    obj.satisfactions_attributes.push( sat )
                }    
            })
        }

        
        return ( obj )
    }

    state = {
        impacts: [],
        species: [],
        checkedA: true,
        prerep: this.buildInitialObject()
    }

    

    componentDidMount() {


        this.setState({ prerep: this.state.prerep });

        fetch(this.props.impactsUrl)
            .then(response => response.json())
            .then(data => {
                this.setState({ impacts: data.data })
                const observations = JSON.parse( this.props.object ).observations
                data.data.map(impact => {
                    const state = observations.filter(obs => obs.user_id == this.props.userId && obs.impact_id == impact.id).length > 0
                        ? true
                        : false
                    this.setState({ [impact.name]: state})
                })
            })

        fetch(this.props.species.remoteUrl)
            .then(response => response.json())
            .then(data => {
                this.setState({ species: data.data })
            })

        


    }

    handleChange = (obs) => {
        obs.present = !obs.present
        this.setState({ prerep: this.state.prerep });

        //e.persist();
        //console.log("impact:", e.target.value)
        //this.setState(prevState => ({
            //[e.target.value]: !prevState[e.target.value]
        //}));
    }

    handleAddSighting = () => {
        // we inject a new empty sighting ...
        this.state.prerep.sightings_attributes.push(
            Object.assign({}, this.emptySighting)
        )
        // ... and then update state so that the component rerenders
        this.setState({ prerep: this.state.prerep });
    }

    handleRemoveSighting(sighting) {
        sighting._destroy = true;
        this.setState({ prerep: this.state.prerep });
    }

    handleChangeCount(e,sighting) {
        sighting.count = e.target.value
        this.setState({ prerep: this.state.prerep });
    }

    renderSuggestion = (suggestionProps) => {
        const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
        const isHighlighted = highlightedIndex === index;
        
        // if we try to access the selectedItem.id directly it raises an error 
        // on page load because the id does not exist. selectedItem = []
        // so we check if selectedItem exists
        const selectedItemId = selectedItem ? selectedItem.id : ''
        const suggestionId = suggestion ? suggestion.id : ''
        
        const isSelected = selectedItemId === suggestionId //(selectedItemId).indexOf(suggestion ) > -1;
      
        return (
            <MenuItem 
                className= "row"
                {...itemProps}
                key={suggestion.id}
                selected={isHighlighted}
                component="div"
                style={{
                    fontWeight: isSelected ? 500 : 400,
                }}
            >

                <div className="col-2 text-center">
                    
                    <Avatar 
                        alt={suggestion.name}
                        src={suggestion.avatar}
                        style={{  margin: 0, backgroundColor:"#134886"}}
                        >
                        {suggestion.name.match(/\b(\w)/g).join('').toUpperCase()}
                        
                    </Avatar>
                </div>
                <div className="col-10">
                    <p className="mb-0"><b>{suggestion.name}</b></p>
                    <div className="row">
                        <div className="col d-flex justify-content-between">
                            <p className="mb-0 text-left">
                                <small>{suggestion.family}</small>
                            </p>
                        </div>
                    </div>
                    
                </div>

            </MenuItem>



        );
    }

    



    formatValueToDisplay = (item) => {
        return item 
            ? `${item.name}`
            : ""
    }

    // when user selects an item from the dropdown
    handleRemoteItemChange = (selection, sighting) => {
        
        sighting.species_id = selection.id;
        sighting.species = selection.name
        this.setState({ prerep: this.state.prerep });

        // we run the parent function if defined
        //if (typeof this.props.onSelectCountry === 'function') {
            //this.props.onSelectCountry(selection);
        //}

    }

    
    handleSatisfactionChange = (e, satisfaction) => {
        satisfaction.score = e.target.value
        this.setState({ prerep: this.state.prerep });
    }

    satisfactionIcon = (label, state) => {
        const iconColor = (label, state ) => {
            if ( !state ) return undefined;
            switch (label) {
                case "very_dissatisfied":
                    return "primary"
                case "dissatisfied":
                    return "primary"
                case "neutral":
                    return "primary"
                case "satisfied":
                    return "primary"
                case "very_satisfied":
                    return "primary"
                default:
                    return undefined 
                }
        };
        
        
        
        switch (label) {
            case 'very_dissatisfied':
                return (
                    <SentimentVeryDissatisfiedIcon fontSize="large" color={iconColor(label,state)} />
                )
            case 'dissatisfied':
                return (
                    <SentimentDissatisfiedIcon fontSize="large" color={iconColor(label,state)} />
                )
            case 'neutral':
                return (
                    <SentimentSatisfiedIcon fontSize="large" color={iconColor(label,state)} />
                )
            case 'satisfied':
                return (
                    <SentimentSatisfiedAltIcon fontSize="large" color={iconColor(label,state)} />
                )
            case 'very_satisfied':
                return (
                    <SentimentVerySatisfiedIcon fontSize="large" color={iconColor(label,state)} />
                )
            default:
                return null
        }
    }

    objectNameFromSatisfactionableType = (sat) => {
        
        
        switch (sat.satisfactionable_type) {
            case 'Divesite':
                return (this.state.prerep.divesite)
            case 'Organisation':
                return ( 
                    this.state.prerep.organisations.filter( 
                        org => org.id === sat.satisfactionable_id)[0].name 
                    )
            case 'User':
                return (this.state.prerep.user)
            default:
                return undefined
        }

    }

    labelFromSatisfactionableType = (sat) => {
        const objName = (sat) => this.objectNameFromSatisfactionableType(sat) 
        
        
        const fieldLabel = (sat) => {
            switch (sat.satisfactionable_type) {
                case 'Divesite':
                    return (
                        "Rate your satisfaction with the conservation status of the wildlife and ecosystems at this site."
                    )
                case 'Organisation':
                    return ( 
                        "Rate your satisfaction with the conservation and sustainability credentials of this organisation."
                    )
                case 'User':
                    return (
                        "Rate your satisfaction with the conservation information and briefings provided by this guide."    
                    )
                default:
                    return undefined
            }
        }

        return(
            <p>
                <b>{objName(sat)}</b> <i><small>- {fieldLabel(sat)}</small></i>
                
            </p>
        )
    }

    

    render () {

        const csrfToken = ( document.querySelectorAll('meta[name=csrf-token]').length > 0 )
            ? document.querySelector('meta[name=csrf-token]').getAttribute('content')
            : ""

        const errors = JSON.parse( this.props.errors )
        const object = JSON.parse( this.props.object )

        

        return(
            <React.Fragment>
                <MuiThemeProvider theme={theme}>
                    <form 
                        id="edit_prerep" 
                        autoComplete="off"     
                        noValidate="novalidate" 
                        action= {this.props.action}
                        method="post"
                        >

                        { (Object.keys(errors).length > 0 ) ? (
                                
                            <div className="alert alert-danger">
                                <p>Well, this is embarassing! Could you help us out by taking a look at the highlighted items.</p>
                            </div>
                        ) : null }

            
                                

                        { this.props.method == "patch"
                            ? ( <input type='hidden' name='_method' value='patch' /> )
                            : null
                        }
                        <input type='hidden' name='utf8' value='✓' />
                        <input type='hidden' name='authenticity_token' value={csrfToken} />

                        <fieldset className="mt-40">
                            <legend>Sightings</legend>
                            <p>Record the marine widlife you encountered.</p>

                            
                            
                            <Grid container spacing={2}>
                                <Grid item xs={8}><InputLabel>Species</InputLabel></Grid>
                                <Grid item xs={3}><InputLabel>Count</InputLabel></Grid>
                            </Grid>

                            {this.state.prerep.sightings_attributes.map( (sighting, i) => {

                                const id = i
                                
                                const selectedItem = sighting.species_id
                                    ? {
                                            id: sighting.species_id,
                                            name: sighting.species
                                    }
                                    : undefined
                                
                                return (
                                    <FormGroup key={id} row className={sighting._destroy ? 'sighting-fields hidden' : "sighting-fields"}>
                                        <Grid container spacing={2}>
                                            { ( "status" in sighting && sighting.status == '_new' ) 
                                                ? (
                                                    <React.Fragment>
                                                        <Grid item xs={8}>"new one" </Grid>
                                                        <Grid item xs={8}>"new one" </Grid>
                                                    </React.Fragment>
                                                )
                                                : ( 
                                                    <Grid item xs={8}>
                                                        <SelectRemote 
                                                            name = {`prerep[sightings_attributes][${id}][species_id]`}
                                                            label = "Species"
                                                            canCreateNew = { false }
                                                            remoteUrl = {this.props.species.remoteUrl}
                                                            formatValueToDisplay = { this.formatValueToDisplay }
                                                            renderSuggestion = { this.renderSuggestion }
                                                            onRemoteItemChange = { selection => this.handleRemoteItemChange(selection, sighting) } 
                                                            inputId = {`species_select_${id}`}
                                                            selectedItem = {selectedItem}
                                                            labelClassName = "sr-only"
                                                            
                                                        />
                                                    </Grid>
                                                )}
                                            <Grid item xs={3}>
                                                <TextField
                                                    id={`prerep_sightings_attributes_${id}_count`}
                                                    fullWidth
                                                    name = {`prerep[sightings_attributes][${id}][count]`}
                                                    onChange = {e => this.handleChangeCount(e,sighting)}
                                                    label="Count"
                                                    value={sighting.count}
                                                    type="number"
                                                    error={ false }
                                                    helperText = { undefined } 
                                                    InputLabelProps={{
                                                        className: "sr-only",
                                                    }}
                                                    InputProps={{
                                                        inputProps: {
                                                            max: "1000",
                                                            min: "0"
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <input readOnly type='hidden' name={`prerep[sightings_attributes][${id}][id]`} value={sighting.id} />
                                                <input readOnly type='hidden' name={`prerep[sightings_attributes][${id}][user_id]`} value={sighting.user_id} />
                                                <input readOnly type='hidden' name={`prerep[sightings_attributes][${id}][_destroy]`} value={sighting._destroy} />
                                                <Button style={{ marginTop: 16 }} color="error.main" onClick={e => this.handleRemoveSighting(sighting)}>
                                                    <DeleteForever />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </FormGroup>     
                                )
                            })}


                            <Button variant="outlined" onClick={e => this.handleAddSighting()}>
                                + Add Sighting
                            </Button>

                            {//object.newSightings.map( (sighting, i) => {
//                                return (
//                                    <FormGroup key={sighting.id} row>
//                                        <input readOnly type='text' name={`prerep[observations_attributes][${sighting}][id]`} value={sighting} />
//                                        <input readOnly type='text' name={`prerep[observations_attributes][${sighting}][user_id]`} value={this.props.user_id} />
//                                        <input readOnly type='text' name={`prerep[observations_attributes][${sighting}][species_id]`} value={sighting} />
//                                        <input readOnly type='text' name={`prerep[observations_attributes][${sighting}][count]`} value={this.state[`sighting-${sighting}-count`]} />
//                                        <input readOnly type='text' name={`prerep[observations_attributes][${sighting}][_destroy]`} value={this.state[sighting.name] ? 0 : 1} />
//                                    </FormGroup>     
//                                )
//                            })
}

                        </fieldset>

                        <fieldset className="mt-40">
                            <legend>Impacts</legend>
                            <p>Record any impacts you observed.</p>


                            {   this.state.impacts.length > 0
                                ? (
                                    this.state.prerep.observations_attributes.map( (obs, i) => {

                                        const impact = this.state.impacts.filter(impact => impact.id == obs.impact_id)[0] 
                                        
                                        return (
                                            <FormGroup key={i} row>
                                                <input readOnly type='hidden' name={`prerep[observations_attributes][${i}][id]`} value={obs.id} />
                                                <input readOnly type='hidden' name={`prerep[observations_attributes][${i}][user_id]`} value={obs.user_id} />
                                                <input readOnly type='hidden' name={`prerep[observations_attributes][${i}][impact_id]`} value={obs.impact_id} />
                                                <input readOnly type='hidden' name={`prerep[observations_attributes][${i}][present]`} value={obs.present} />
                                                <FormControlLabel
                                                    control={
                                                        <Switch 
                                                            checked={obs.present} 
                                                            onChange={e => this.handleChange(obs)} 
                                                            value={obs.id} />
                                                    }
                                                    label={ impact.name }
                                                />
                                            </FormGroup>
                                            
                                        )
                                    })
                                )  
                                : null  
                            }

                            

                        </fieldset>

                        <fieldset className="mt-40">
                            <legend>Satisfaction</legend>
                            
                            { this.state.prerep.satisfactions_attributes.map( (sat, i) => {
                                
                                const objName = this.objectNameFromSatisfactionableType(sat)

                                const radioGroupLabel = this.labelFromSatisfactionableType(sat)
                                
                                return (
                                    <FormControl key={i} component="fieldset" className="mb-20">
                                        <FormLabel component="legend">{ radioGroupLabel }</FormLabel>
                                        
                                        <input readOnly type='hidden' name={`prerep[satisfactions_attributes][${i}][id]`} value={sat.id} />
                                        <input readOnly type='hidden' name={`prerep[satisfactions_attributes][${i}][user_id]`} value={sat.user_id} />
                                        <input readOnly type='hidden' name={`prerep[satisfactions_attributes][${i}][satisfactionable_id]`} value={sat.satisfactionable_id} />
                                        <input readOnly type='hidden' name={`prerep[satisfactions_attributes][${i}][satisfactionable_type]`} value={sat.satisfactionable_type} />
                                        <input readOnly type='hidden' name={`prerep[satisfactions_attributes][${i}][score]`} value={sat.score} />
                                        <RadioGroup aria-label={`${objName} satisfaction`} name={`prerep[satisfactions_attributes][${i}][score]`} value={sat.score} onChange={ e => this.handleSatisfactionChange(e,sat) } row>
                                            { Object.keys(this.props.satisfactionLabels).map( (label, i) => {
                                                return (
                                                    
                                                    <FormControlLabel 
                                                        key={i}
                                                        value={label} 
                                                        label={
                                                            <span className="sr-only">
                                                                { label }
                                                            </span>
                                                        }
                                                        control={
                                                            <Radio
                                                                color="default"
                                                                checkedIcon={ this.satisfactionIcon(label, true) }
                                                                icon={ this.satisfactionIcon(label, false) }
                                                                inputProps={{ 'aria-label': label }}
                                                                /> 
                                                        }/>
                                                        
                                                            
                                                    
                                                )
                                                
                                            })}
                                        </RadioGroup>
                                    </FormControl>
                                    
                                )
                            })}
                            
                        </fieldset>
                        
                        <div className="form-group text-center">
                            <Link href={this.props.cancelUrl} className="btn btn-secondary">
                                Cancel
                            </Link>
                            <input type="submit" name="commit" type="submit" value="Save" className="btn btn-primary" />
                        </div>

                            
                        </form>
                </MuiThemeProvider>
                
            </React.Fragment>
        )
    }
}

LogForm.propTypes = {
    object: PropTypes.string,
    errors: PropTypes.string,
    method: PropTypes.string,
    action: PropTypes.string,
    cancelUrl: PropTypes.string,
    impactsUrl: PropTypes.string,
    userId: PropTypes.number,
    species: PropTypes.shape({
        remoteUrl: PropTypes.string.isRequired,
    }).isRequired,

}

LogForm.defaultProps = {
    errors: "{}",
    prerep: JSON.stringify(
        {
            id: '',
            errors: {},
            sightings_attributes: [Object.assign({}, LogForm.emptySighting)]
        } 
    )
}

export default LogForm