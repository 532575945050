import React, { useState } from "react";
import PropTypes from "prop-types"

import SelectRemote from "components/SelectRemote"
import MenuItem from '@material-ui/core/MenuItem';



class SelectCountry extends React.Component {
    state = {
        fetchedData: this.props.fetchedData,
        searchCache: this.props.searchCache,
        selectedItem: this.props.selectedItem,
        inputValue: ""
    };

    
    

 
    renderSuggestion = (suggestionProps) => {
        const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
        const isHighlighted = highlightedIndex === index;
        
        // if we try to access the selectedItem.id directly it raises an error 
        // on page load because the id does not exist. selectedItem = []
        // so we check if selectedItem exists
        const selectedItemId = selectedItem ? selectedItem.id : ''
        const suggestionId = suggestion ? ( suggestion.id || suggestion.properties.id ) : ''
        
        const isSelected = selectedItemId === suggestionId //(selectedItemId).indexOf(suggestion ) > -1;
      
        return (
            <MenuItem 
                className= "row"
                {...itemProps}
                key={suggestion.properties.id}
                selected={isHighlighted}
                component="div"
                style={{
                    fontWeight: isSelected ? 500 : 400,
                }}
            >

                <div className="col-2 text-center">
                    <span className={`flag-icon flag-icon-${suggestion.properties.alpha.toLowerCase()}`} />
                </div>
                <div className="col-10">
                    <p className="mb-0"><b>{suggestion.properties.name}</b></p>
                    <div className="row">
                        <div className="col d-flex justify-content-between">
                            <p className="mb-0 text-left">
                                <small></small>
                            </p>
                            <p className="mb-0 text-right">
                                { [...Array(suggestion.properties.rating)].map((e, i) => <i className="fas fa-star" key={i}></i>) }
                                { [...Array(5-suggestion.properties.rating)].map((e, i) => <i className="far fa-star" key={i}></i>) }
                            </p>
                        </div>
                    </div>
                    
                </div>

            </MenuItem>



        );
    }

    



    formatValueToDisplay = (item) => {
        return item 
            ? `${item.properties.name}`
            : ""
    }

    // when user selects an item from the dropdown
    handleRemoteItemChange = (selection) => {

        // we run the parent function if defined
        if (typeof this.props.onSelectCountry === 'function') {
            this.props.onSelectCountry(selection);
        }

    }

    render () {


        // itemToString extracts the name key from the hash 

        return (
            
            <SelectRemote 
                name = { this.props.name }
                label = { this.props.label }
                remoteUrl = {this.props.remoteUrl}
                formatValueToDisplay = { this.formatValueToDisplay }
                renderSuggestion = { this.renderSuggestion }
                onRemoteItemChange = { this.handleRemoteItemChange } 
                inputId = "country_select"
                
            />
        )

    }
}


SelectCountry.propTypes = {
    divesitesRemoteUrl:PropTypes.string,
    regionsRemoteUrl: PropTypes.string,
    countriesRemoteUrl: PropTypes.string,
    options: PropTypes.array,
    name: PropTypes.string,
    label: PropTypes.string,
    inputOnChange: PropTypes.func,
    formatValueToDisplay: PropTypes.func,
    onSelectCountry: PropTypes.func,
}


SelectCountry.defaultProps = {
    divesitesRemoteUrl: "http.divesite.api",
    regionsRemoteUrl: "http.divesite.api",
    countriesRemoteUrl: "http.divesite.api",
    options: [],
    name: "",
    label: "",
    onSelectCountry: null
}




export default SelectCountry