// https://gist.github.com/cseelus/63baa4f46f6f0ae0c4630a3987022f97
import { DirectUpload } from 'activestorage'
import Rails from 'rails-ujs';

// See DirectUploadController from Rails Active Storage source
export class CustomUploader {
    constructor(input, file) {
        
        this.input = input
        this.file = file
        this.directUpload = new DirectUpload(this.file, this.url, this)
        this.dispatch("initialize")
    }

    start() {
        console.log("---initialize uploader---")
        const hiddenInput = document.createElement("input")
        const origInput = this.input
        hiddenInput.id = "hiddenInput"
        hiddenInput.type = "hidden"
        console.log("NNNAAAMMMMEE")
        console.log(origInput.name.substr(0, origInput.name.lastIndexOf("[")))
        // we set the new hidden input name to == the original input name 
        // BUT we remove the final []. The original input was multiple, but we are now 
        // submitting each image sequentially
        if ( origInput.hasAttribute("multiple") ) {
            hiddenInput.name = origInput.name.substr(0, origInput.name.lastIndexOf("["))
        } else {
            hiddenInput.name = origInput.name
        }
            
        
        hiddenInput.classList.add('cache')
        this.input.insertAdjacentElement("beforebegin", hiddenInput)

        this.dispatch("start")

        this.directUpload.create((error, blob) => {
            if (error) {
                hiddenInput.parentNode.removeChild(hiddenInput)
                this.dispatchError(error)
            } else {
                hiddenInput.value = blob.signed_id
                

                if (origInput.hasAttribute("data-autoupload")) {
                    if (origInput.dataset.autoupload == "true") {
                        console.log("---auto upload---")
                        // if the input is an autoloader, we gather up the form 
                        // inputs, construct params, and submit via ajax
                        var form    = document.querySelector("#"+origInput.id).closest("form")
                        // get the URL the form submits to 
                        var action  = form.action
                        
                        var formData = new FormData(form)
                        console.log("HERES THE FORM")
                        
                        for (var pair of formData.entries()) {
                            console.log(pair[0] + ', ' + pair[1]);
                        }

                        // we delete the original image to prevent param expectation errors
                        // on submitting to Rails
                        formData.delete("asset[file][]")
                        
                        
                        for (var pair of formData.entries()) {
                            console.log(pair[0] + ', ' + pair[1]);
                        }
                        
                        // get the form attributes 
                        var attr = Array.from(formData, e => e.map(encodeURIComponent).join('='))
                        // explicitly request JS format
                        attr.push("format=js")
                        // format the payload for transmission
                        var payload = attr.join('&')

                        //// initialize an xry request
                        //var xhr     = new XMLHttpRequest()
                        //// post to the form action
                        //xhr.open("POST", action)
                        //// we need to set the content type
                        //xhr.setRequestHeader("Content-type", "application/x-form-urlencoded")
                        //// and the CSRF token
                        //var token = document.querySelector('meta[name="csrf-token"]').content
                        //xhr.setRequestHeader("X-CSRF-Token", token)

                        // submit using Rails-ujs 
                        Rails.ajax({
                            type: "POST",
                            url: action,
                            data: payload,
                            success( repsonse) {
                                // reset the hidden field 
                                hiddenInput.value = null
                            },
                            error( repsonse) {
                            }
                        })



//                        //This is a bit tricky, [].fn.call(form.elements, ...) allows us to call .fn
//                        //on the form's elements, even though it's not an array. Effectively
//                        //Filtering all of the fields on the form
//                        var payload = [].filter.call(form.elements, function (el) {
//                            //Allow only elements that don't have the 'checked' property
//                            //Or those who have it, and it's checked for them.
//                            return typeof (el.checked) === 'undefined' || el.checked;
//                            //Practically, filter out checkboxes/radios which aren't checekd.
//                        })
//                            //.filter(function (el) { return !!el.name; }) //Nameless elements die.
//                            //.filter(function (el) { return el.disabled; }) //Disabled elements die.
//                            .map(function (el) {
//                                //Map each field into a name=value string, make sure to properly escape!
//                                return encodeURIComponent(el.name) + '=' + encodeURIComponent(el.value);
//                            }).join('&'); //Then join all the strings by &
//
//                        console.log(payload)


                        //console.log(document.querySelector("#"+hiddenInput.id).parentNode)
                        //.bind ensures that this inside of the function is the XHR object.
                        
                        //xhr.onload = hiddenInput.value = null

                        //All preperations are clear, send the request!
                        //xhr.send(payload)

                    }
                }
            }

            console.log("event1")
            console.log(this.directUpload.id)
            // if there is a preview, we replace the ID with the signed ID
            // so that we can later access it from controller and JS views
            const preview = document.getElementById(`direct-upload-preview-${this.directUpload.id}`)
            
            
            console.log(preview)
            if (preview) {
                console.log(blob.signed_id)
                preview.id = `direct-upload-preview-${blob.signed_id}`
            }
            console.log("this will call end")
            console.log(this)
            this.dispatch("end")
            // callback(error)
        })
    }

    uploadRequestDidProgress(event) {
        const progress = event.loaded / event.total * 100
        if (progress) {
            this.dispatch("progress", { progress })
        }
    }

    get url() {
        return this.input.getAttribute("data-direct-upload-url")
    }

    dispatch(name, detail = {}) {
        detail.file = this.file
        detail.id = this.directUpload.id
        return dispatchEvent(this.input, `direct-upload:${name}`, { detail })
    }

    dispatchError(error) {
        const event = this.dispatch("error", { error })
        if (!event.defaultPrevented) {
            alert(error)
        }
    }

    directUploadWillStoreFileWithXHR(xhr) {
        this.dispatch("before-storage-request", { xhr })
        xhr.upload.addEventListener("progress", event => this.uploadRequestDidProgress(event))
    }
}

function dispatchEvent(element, type, eventInit = {}) {
    const { disabled } = element
    const { bubbles, cancelable, detail } = eventInit
    const event = document.createEvent("Event")

    event.initEvent(type, bubbles || true, cancelable || true)
    event.detail = detail || {}

    try {
        element.disabled = false
        element.dispatchEvent(event)
    } finally {
        element.disabled = disabled
    }

    return event
}



//import { DirectUpload } from "activestorage"
//
//class Uploader {
//    constructor(file, url) {
//        this.upload = new DirectUpload(this.file, this.url, this)
//    }
//
//    upload(file) {
//        this.upload.create((error, blob) => {
//            if (error) {
//                // Handle the error
//            } else {
//                const hiddenField = document.createElement('input')
//                hiddenField.setAttribute("type", "hidden");
//                hiddenField.setAttribute("value", blob.signed_id);
//                hiddenField.name = input.name
//                document.querySelector('form').appendChild(hiddenField)
//
//                // Add an appropriately-named hidden input to the form
//                // with a value of blob.signed_id
//            }
//        })
//    }
//
//    directUploadWillStoreFileWithXHR(request) {
//        request.upload.addEventListener("progress",
//            event => this.directUploadDidProgress(event))
//    }
//
//    directUploadDidProgress(event) {
//        maxprogress = event.total
//        current     = event.loaded
//        progress    = current / maxprogress
//        const $progressBar = document.querySelector('.progress')
//
//        if (progress < maxprogress) {
//            $progressBar.attr('value', progress);
//            //animate();
//        } else {
//            $progressBar.css('display', 'none');
//        }
//        // Use event.loaded and event.total to update the progress bar
//    }
//}
//
//
//
//
//
//$('input').on('click', function () {
//    var valeur = 0;
//    $('input:checked').each(function () {
//        if ($(this).attr('value') > valeur) {
//            valeur = $(this).attr('value');
//        }
//    });
//    $('.progress-bar').css('width', valeur + '%').attr('aria-valuenow', valeur);
//});
//
