import React from "react"
import PropTypes from "prop-types"

// custom reusable Material-Table template 
import Datatable from "components/Datatable"

// for the toolbar new button 
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';

// for row Avatar 
import Avatar from '@material-ui/core/Avatar';

class DatatableOrganisations extends React.Component {
  render () {
    return (
      <Datatable
        title= {this.props.title}
        columns= {[
          { title: 'ID', field: 'id', filtering: false },
          {
            title: 'Avatar',
            field: 'avatar',
            filtering: false,
            render: rowData => (
              <Avatar 
                alt={rowData.name}
                src={rowData.avatar}
                style={{  margin: 0, backgroundColor:"#134886"}}
              >
                {rowData.name.match(/\b(\w)/g).join('').toUpperCase()}
              </Avatar>
            ),
          },
          { title: 'Name', field: 'name', filtering: false },
          { 
            title: 'Type', 
            field: 'type', 
            lookup: JSON.parse( this.props.typeFilter )
          },
          { 
            title: 'Country', 
            field: 'country_id',
            lookup: JSON.parse( this.props.countryFilter )
          }
        ]}
        remoteUrl="/api/organisations"
        toolbarContent={
          <div style={{padding: '0px 10px'}}>
            <a
              href="/operators/new"
              >
              <Chip 
                icon={<Icon>add_circle</Icon>}
                label="New Operator"
                onClick={ true }
                variant="outlined"
                color="primary"
              />
            </a>
            <a
              href="/universities/new"
              >
              <Chip 
                icon={<Icon>add_circle</Icon>}
                label="New University"
                onClick={ true }
                variant="outlined"
                color="primary"
              />
            </a>
            
          </div>
        }
      />

    );
  }
}

DatatableOrganisations.propTypes = {
  title: PropTypes.string,
  countryFilter: PropTypes.string,
  typeFilter: PropTypes.string
};

export default DatatableOrganisations
