import MaterialTable from "material-table";
function Datatablexxx(props) {
    return (
        <div style={{ maxWidth: "100%" }}>
            <MaterialTable
                title= {props.title}
                columns= {props.columns}
                data={query =>
                    new Promise((resolve, reject) => {
                    let url = props.url
                    url += '?per_page=' + query.pageSize
                    url += '&page=' + (query.page + 1)
                    fetch(url)
                        .then(response => response.json())
                        .then(result => {
                        resolve({
                            data: result.data,
                            page: result.page - 1,
                            totalCount: result.total,
                        })
                        })
                    })
                }
            
            />
        </div>
    );
}

Datatable.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    columns: PropTypes.array
};

  
Datatable.defaultProps = {
    title: "This is the title",
    url: "nil",
    columns: []
}