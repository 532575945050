import React from "react"
import FullScreen from "react-request-fullscreen"
import PropTypes from "prop-types"







class Fullscreen extends React.Component{

  render() {
    return (
      <React.Fragment>
        <FullScreen ref={ref => this.fullScreenRef = ref} onFullScreenChange={isFullScreen => {
          this.setState({
            isFullScreen,
          })}}>
          <div 
            onClick={() => this.fullScreenRef.fullScreen()} 
            style={{cursor: 'pointer'}}
            className={"nav-link icon icon-fullscreen"}
            role={"button"}>
            <span className="sr-only">Toggle fullscreen</span>
          </div>
        </FullScreen>
      </React.Fragment>
    )
  }

}
export default Fullscreen
