export class ResizeImage {

    constructor(file,mWidth,mHeight) {
        this.file = file
        this.mimetype = file.type
        this.maxWidth = mWidth || 1200
        this.maxHeight = mHeight || 1200
    }

    process(input,filename,callback) {
        console.log("---initialize resizer---")
        // initialize a FileReader ...
        var reader = new FileReader
        const { mimetype, maxWidth, maxHeight } = this
                
        reader.onload = readSuccess
        
        function readSuccess(e) {
            // ...and create an image object ...
            let img = new Image

            img.onload = imageAvailable
            
            // we wrap these in an onload callback 
            // to ensure the image is available
            function imageAvailable(e) {
                
                // ...which will be passed to ResizeImage
                // to check the dimensions
                let resized = resizeImage(img, mimetype, maxWidth, maxHeight)
                callback(input, filename, resized)
            }
            img.src = e.target.result

            

            
        };

        reader.readAsDataURL(this.file)

    }

}

function resizeImage(img,mimetype,maxWidth,maxHeight) {

    var width
    var height
    
    var MAX_WIDTH = maxWidth
    var MAX_HEIGHT = maxHeight
    var canvas = document.createElement("canvas")
    
    width   = img.width
    height  = img.height

    console.log("dimensions1")
    console.log(width)
    console.log(height)
    console.log(img)

    if (width > height) {
        if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
        }
    } else {
        if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
        }
    }

    console.log("dimensions")
    console.log(width)
    console.log(height)

    canvas.width = width
    canvas.height = height

    var ctx = canvas.getContext("2d")
    ctx.drawImage(img, 0, 0, width, height)

    var dataurl = canvas.toDataURL(mimetype)

    return dataurl


}