import React from "react"
import PropTypes from "prop-types"

// custom reusable Material-Table template 
import Datatable from "components/Datatable"

// for the toolbar new button 
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';

class DatatableDivesites extends React.Component {
  

  render () {
    return (
      <div style={{ maxWidth: "100%" }}>
        <Datatable
          title= {this.props.title}
          columns= {[
            { title: 'ID', field: 'properties.id', filtering: false },
            { title: 'Name', field: 'properties.name', filtering: false },
            { 
              title: 'Region', 
              field: 'properties.region_id' ,
              lookup: JSON.parse( this.props.regionFilter )
            },
            { 
              title: 'Country', 
              field: 'properties.country_id',
              lookup: JSON.parse( this.props.countryFilter )
            }
          ]}
          remoteUrl="/api/divesites"
          toolbarContent={
            <div style={{padding: '0px 10px'}}>
              <a
                href="/divesites/new"
                >
                <Chip 
                  icon={<Icon>add_circle</Icon>}
                  label="New Divesite"
                  onClick={ true }
                  variant="outlined"
                  color="primary"
                />
              </a>
              
            </div>
          }
          
        />
      </div>
    );
  }
}

DatatableDivesites.propTypes = {
  title: PropTypes.string,
  regionFilter: PropTypes.string,
  countryFilter: PropTypes.string,
};

function handleClick() {
  window.location='/divesites/new';
}

export default DatatableDivesites
