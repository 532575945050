import React from "react"
import PropTypes from "prop-types"

// custom reusable Material-Table template 
import Datatable from "components/Datatable"

// for the toolbar new button 
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';

// for row Avatar 
import Avatar from '@material-ui/core/Avatar';


class DatatableSpecies extends React.Component {
  render () {
    
    return (
      <div style={{ maxWidth: "100%" }}>
        <Datatable
          title= {this.props.title}
          columns= {[
            { title: 'ID', field: 'id', filtering: false },
            {
              title: 'Avatar',
              field: 'avatar',
              filtering: false,
              render: rowData => (
                //buildAvatar(rowData)
                <Avatar 
                  alt={rowData.name}
                  src={rowData.avatar}
                  style={{  margin: 0, backgroundColor:"#134886"}}
                >
                  {rowData.name.match(/\b(\w)/g).join('').toUpperCase()}
                </Avatar>
              ),
            },
            { title: 'Name', field: 'name', filtering: false },
            { 
              title: 'Family', 
              field: 'family_id', 
              lookup: JSON.parse( this.props.familyFilter ) 
            }
          ]}
          remoteUrl="/api/species"
          toolbarContent={
            <div style={{padding: '0px 10px'}}>
              <a
                href="/species/new"
                >
                <Chip 
                  icon={<Icon>add_circle</Icon>}
                  label="New Species"
                  onClick={ true }
                  variant="outlined"
                  color="primary"
                />
              </a>
              
            </div>
          }
          
          
          
          
        />
      </div>
    );
  }
}

DatatableSpecies.propTypes = {
  title: PropTypes.string,
  familyFilter: PropTypes.string
};


function buildInitials(name) {
  name.match(/\b(\w)/g).join('').toUpperCase()
}

export default DatatableSpecies
