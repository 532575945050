import React, { useState } from "react";
import PropTypes from "prop-types"

import SelectDivesite from "components/SelectDivesite"
import SelectRegion from "components/SelectRegion"
import SelectCountry from "components/SelectCountry"
import DatePickerInput from "components/DatePickerInput"
import OrganisationTagSelect from "components/OrganisationTagSelect"
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import Mapbox from "./Mapbox";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
  } from '@material-ui/pickers';


const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: blue,
  },
});


class PrerepForm extends React.Component {
    state = {
        divesiteIsNew: false,
        regionIsNew: false,
        lat: undefined,
        lng: undefined,
        zoom: undefined,
        countryId: undefined,
        csrfToken: undefined,
        date: JSON.parse( this.props.object ).date,
        time: JSON.parse( this.props.object ).time || null,
        duration: JSON.parse( this.props.object ).duration || "",
        depth: JSON.parse( this.props.object ).depth || "",
        grpsize: JSON.parse( this.props.object ).grp_size || "",
        boats: JSON.parse( this.props.object ).boats || "",
        temp: JSON.parse( this.props.object ).temp || "",
        current: JSON.parse( this.props.object ).current || "",
        notes: JSON.parse( this.props.object ).notes || "",
        selectedDivesite: JSON.parse( this.props.object ).divesite_id
            ? {
                id: JSON.parse( this.props.object ).divesite_id,
                name: JSON.parse( this.props.object ).divesite,
            } 
            : undefined ,
        moreDetail: Object.keys( JSON.parse( this.props.errors ) ).length > 0 ? true : false,
        clockIsOpen: false
    };

    
    

 
    // when user selects an item from the dropdown
    handleSelectDivesite = (selection) => {
        if ('status' in selection && selection.status === "_new") {
            this.setState({ divesiteIsNew: true });
            this.setState({ lat: undefined });
            this.setState({ lng: undefined });
            this.setState({ zoom: undefined });
            this.setState({ countryId: 0 });
        } else {
            console.log("setLat:", selection.geometry.coordinates[0])
            console.log("setLng:", selection.geometry.coordinates[1])

            this.setState({ divesiteIsNew: false });
            this.setState({ lat: selection.geometry.coordinates[0] });
            this.setState({ lng: selection.geometry.coordinates[1] });
            this.setState({ zoom: 10 });
            this.setState({ countryId: selection.properties.country_id });
        }
    }

    // when user selects an item from the dropdown
    handleSelectRegion = (selection) => {
        if ('status' in selection && selection.status === "_new") {
            this.setState({ regionIsNew: true });
            this.setState({ lat: undefined });
            this.setState({ lng: undefined });
            this.setState({ zoom: undefined });
            this.setState({ countryId: 0 });
        } else {
            this.setState({ regionIsNew: false });
            this.setState({ lat: selection.geometry.coordinates[0] });
            this.setState({ lng: selection.geometry.coordinates[1] });
            this.setState({ zoom: 8 });
            this.setState({ countryId: selection.properties.country_id });
        }
    }

    // when user selects an item from the dropdown
    handleSelectCountry = (selection) => {
        this.setState({ lat: selection.geometry.coordinates[0] });
        this.setState({ lng: selection.geometry.coordinates[1] });
        this.setState({ zoom: 2.5 });
        this.setState({ countryId: selection.properties.id });
    }

    handleChangeTime = (time) => {
        this.setState({ time: time });
    }

    handleChangeDuration = (e) => {
        this.setState({ duration: e.target.value });
    }

    handleChangeDepth = (e) => {
        this.setState({ depth: e.target.value });
    }

    handleChangeGrpsize = (e) => {
        this.setState({ grpsize: e.target.value });
    }

    handleChangeBoats = (e) => {
        this.setState({ boats: e.target.value });
    }

    handleChangeTemp = (e) => {
        this.setState({ temp: e.target.value });
    }

    handleChangeCurrent = (e) => {
        this.setState({ current: e.target.value });
    }

    handleChangeNotes = (e) => {
        this.setState({ notes: e.target.value });
    }

    expandForm = (e) => {
        e.preventDefault();
        const state = this.state.moreDetail
            ? false
            : true
        this.setState({ moreDetail: state });
    }
    setIsOpen(status) {
        this.setState({ clockIsOpen: status });
    }

//    componentDidMount() {
//        const csrfToken = document.querySelector('meta[name=csrf-token]').getAttribute('content')
//        this.setState({ csrfToken: csrfToken });
//    }

    

    render () {

        const csrfToken = ( document.querySelectorAll('meta[name=csrf-token]').length > 0 )
            ? document.querySelector('meta[name=csrf-token]').getAttribute('content')
            : ""

        const errors = JSON.parse( this.props.errors )

        const currents = [
            {
              value: '1',
              label: 'No current - able to hold position without finning',
            },
            {
              value: '2',
              label: 'Slight - able to hold position with little effort.',
            },
            {
              value: '3',
              label: 'Moderate - able to hold position with strong finning.',
            },
            {
              value: '4',
              label: 'Strong - unable to hold position.',
            },
        ];

        console.log("object:", this.props.object)
        console.log("errors:", this.props.errors )
        console.log("errors length:", this.props.errors.length )
        console.log("errors.length:", Object.keys(errors).length )

        if ("divesite" in errors) {
            console.log("divesite errors:", errors.divesite )
        } 
        return( 
            <React.Fragment>
                <MuiThemeProvider theme={theme}>

                <div className="card">
				

                    <Mapbox
                        lat={ this.state.lat }
                        lng={ this.state.lng }
                        zoom={ this.state.zoom }
                    /> 

                    <div className="card-body">

                        <form 
                            className="js-track-event js-track-view-event" 
                            id="new_prerep" 
                            autoComplete="off" 
                            data-event-category="Survey" 
                            data-event-action="Viewed Form" 
                            data-event-target-name= { this.props.targetName } //"Create an Ocean Report" 
                            data-event-target-object="page" 
                            data-event-target-source={ this.props.targetSource }
                            noValidate="novalidate" 
                            action= {this.props.action}
                            method="post">



                            { (Object.keys(errors).length > 0 ) ? (
                                
                                <div className="alert alert-danger">
                                    <p>Well, this is embarassing! Could you help us out by taking a look at the highlighted items.</p>
                                </div>
                            ) : null }

            
                                

                            { this.props.method == "patch"
                                ? ( <input type='hidden' name='_method' value='patch' /> )
                                : null
                            }
                            <input type='hidden' name='utf8' value='✓' />
                            <input type='hidden' name='authenticity_token' value={csrfToken} />
					


                        

                            <div className="form-group">
                                <DatePickerInput
                                    name = "formatted_date"
                                    objectName = "prerep"
                                    errorMessage = { "formatted_date" in errors ? `Date ${errors.formatted_date}` : undefined }
                                    value = {this.state.date}
                                    
                                />
                            </div>
                            
                            <div className="form-group divesite-select">
                                <SelectDivesite 
                                    name = "prerep[divesite_id]"
                                    label = "Divesite"
                                    remoteUrl = {this.props.divesite.remoteUrl}
                                    onSelectDivesite = { this.handleSelectDivesite}
                                    helperText = { "divesite" in errors ? `Divesite ${errors.divesite}` : undefined } 
                                    error = { "divesite" in errors ? true : false }
                                    selectedItem = {this.state.selectedDivesite}
                                />
                            </div>
                            

                            { this.state.divesiteIsNew ? (
                                <div className="form-group region-select">
                                    <SelectRegion 
                                        name = "prerep[divesite_attributes][region_id]"
                                        label = "Region"
                                        remoteUrl = {this.props.region.remoteUrl}
                                        onSelectRegion = { this.handleSelectRegion}
                                        helperText = { "region" in errors ? `Country ${errors.region}` : undefined } 
                                        error = { "region" in errors ? true : false }
                                    />
                                </div>
                            ) : null }

                            { this.state.divesiteIsNew && this.state.regionIsNew ? (
                                <div className="form-group country-select">
                                    <SelectCountry 
                                        name = "prerep[divesite_attributes][region_attributes][country_id]"
                                        label = "Country"
                                        remoteUrl = {this.props.country.remoteUrl}
                                        onSelectCountry = { this.handleSelectCountry }
                                        helperText = { "country" in errors ? `Country ${errors.country}` : undefined } 
                                        error = { "country" in errors ? true : false }
                                    />
                                </div>
                            ) : null }

                            <div className="form-group organisations-select">
                                <OrganisationTagSelect
                                    remoteUrl = {this.props.organisation.remoteUrl}
                                    label = "Organisations" 
                                    countryId = {this.state.countryId}
                                    
                                />
                            </div>

                            { this.state.moreDetail ? (
                                <div>
                                    <div className="form-group">
                                    
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker

                                                variant="inline"
                                                id="prerep_formatted_time"
                                                name = "prerep[formatted_time]"
                                                label="Time"
                                                fullWidth= {true}
                                                format="HH:mm"
                                                ampm={false}
                                                error={ "formatted_time" in errors ? true : false }
                                                helperText = { "formatted_time" in errors ? `Time ${errors.formatted_time}` : undefined } 
                                                value={this.state.time}
                                                onChange={selection => this.handleChangeTime(selection)}
                                                open={this.state.clockIsOpen}
                                                onOpen={() => this.setIsOpen(true)}
                                                onClose={() => this.setIsOpen(false)}
                                                //onFocus={() => this.state.clockIsOpen ? null : this.setIsOpen(true)}
                                                //onBlur={() => this.setIsOpen(false)}
                                                autoOk= {true}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                        
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            id="prerep_duration"
                                            name = "prerep[duration]"
                                            onChange = { this.handleChangeDuration}
                                            label="Dive time (mins)"
                                            fullWidth= {true}
                                            value={this.state.duration}
                                            type="number"
                                            error={ "duration" in errors ? true : false }
                                            helperText = { "duration" in errors ? `Dive time (mins) ${errors.duration}` : undefined } 
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    max: "120",
                                                    min: "0"
                                                }
                                            }}
                                            margin="normal"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            id="prerep_depth"
                                            name = "prerep[depth]"
                                            onChange = { this.handleChangeDepth}
                                            label="Depth (m)"
                                            fullWidth= {true}
                                            value={this.state.depth}
                                            type="number"
                                            helperText = { "depth" in errors ? `Depth (m) ${errors.depth}` : undefined } 
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    max: "100",
                                                    min: "1"
                                                }
                                            }}
                                            margin="normal"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            id="prerep_temp"
                                            name = "prerep[temp]"
                                            onChange = { this.handleChangeTemp}
                                            label="Water Temp (°C)"
                                            fullWidth= {true}
                                            value={this.state.temp}
                                            type="number"
                                            helperText = { "temp" in errors ? `Water Temp (°C) ${errors.temp}` : undefined } 
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    max: "50",
                                                    min: "10"
                                                }
                                            }}
                                            margin="normal"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            id="prerep_grpsize"
                                            name = "prerep[grpsize]"
                                            onChange = { this.handleChangeGrpsize}
                                            label="Group size"
                                            fullWidth= {true}
                                            value={this.state.grpsize}
                                            type="number"
                                            helperText = { "grpsize" in errors ? `Group size ${errors.grpsize}` : undefined } 
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    max: "50",
                                                    min: "1"
                                                }
                                            }}
                                            margin="normal"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            id="prerep_boats"
                                            name = "prerep[boats]"
                                            onChange = { this.handleChangeBoats}
                                            label="Boats"
                                            fullWidth= {true}
                                            value={this.state.boats}
                                            type="number"
                                            helperText = { "boats" in errors ? `Boats ${errors.boats}` : undefined } 
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    max: "100",
                                                    min: "1"
                                                }
                                            }}
                                            margin="normal"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            id="prerep_current_code"
                                            name = "prerep[current_code]"
                                            onChange = { this.handleChangeCurrent}
                                            label="Current"
                                            fullWidth= {true}
                                            value={this.state.current}
                                            select
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            margin="normal"
                                        >
                                            {currents.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            id="prerep_notes"
                                            name = "prerep[notes]"
                                            onChange = { this.handleChangeNotes}
                                            label="Notes"
                                            fullWidth= {true}
                                            helperText = { "notes" in errors ? `Notes ${errors.notes}` : undefined } 
                                            value={this.state.notes}
                                            multiline
                                            rowsMax="4"
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            margin="normal"
                                        />
                                    </div>
                                
                                    <Link 
                                        href="javascript:void(0)"
                                        className="btn btn-secondary" 
                                        onClick={this.expandForm}
                                    >
                                        Fewer details
                                    </Link>
                                </div>
                                
                            ) : (
                                <Link 
                                    className="btn btn-secondary" 
                                    href="javascript:void(0)"
                                    title="Add more details"
                                    onClick={this.expandForm}
                                >
                                    Add more details
                                </Link>
                            ) }

                            

                            <div className="form-group text-center">
                                <Link href={this.props.cancelUrl} className="btn btn-default">
                                    Cancel
                                </Link>
                                <input type="submit" name="commit" type="submit" value="Save" className="btn btn-primary" />
                            </div>

                            
                        </form>

                    </div>

                </div>

                
                
                </MuiThemeProvider>
            </React.Fragment>
        )
    }

}

PrerepForm.propTypes = {
    //divesitesRemoteUrl:PropTypes.string,
    //regionsRemoteUrl: PropTypes.string,
    //countriesRemoteUrl: PropTypes.string,
    //options: PropTypes.array,
    //name: PropTypes.string,
    //label: PropTypes.string,
    //inputOnChange: PropTypes.func,
    //formatValueToDisplay: PropTypes.func,
    //canCreateNew: PropTypes.bool,
    //formatValueToDisplay: PropTypes.func,
    //handleOnChange: PropTypes.func,

    object: PropTypes.string,
    errors: PropTypes.string,

    divesite: PropTypes.shape({
        remoteUrl: PropTypes.string.isRequired,
    }).isRequired,
    region: PropTypes.shape({
        remoteUrl: PropTypes.string.isRequired,
    }).isRequired,
    country: PropTypes.shape({
        remoteUrl: PropTypes.string.isRequired,
    }).isRequired,
    organisation: PropTypes.shape({
        remoteUrl: PropTypes.string.isRequired,
    }).isRequired,
}


PrerepForm.defaultProps = {
    divesitesRemoteUrl: "http.divesite.api",
    regionsRemoteUrl: "http.divesite.api",
    countriesRemoteUrl: "http.divesite.api",
    options: [],
    name: "",
    label: "",
    canCreateNew: false,
    formatValueToDisplay: null,
    handleOnChange: null,
    object: undefined,
    errors: "",
    method: "post"
}




export default PrerepForm