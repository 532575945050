import React from "react"
import PropTypes from "prop-types"
//import MaterialTable from "material-table";
import MaterialTable, {MTableToolbar} from 'material-table'
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';



class Datatable extends React.Component {
  
  render () {
    const toolbarContent = this.props.toolbarContent
    return (
      <div style={{ maxWidth: "100%" }}>
        <MaterialTable
          title= {this.props.title}
          columns= {this.props.columns}
          data={query =>
            new Promise((resolve, reject) => {
              let url = this.props.remoteUrl + "?"
              url += 'page_size=' + query.pageSize
              url += '&page=' + (query.page + 1)
              // if we passed a search term
              if (query.search) {
                url += '&q=' + (query.search)
              }
              fetch(url)
                .then(response => response.json())
                .then(result => {
//                  console.log(JSON.stringify(result))
                  resolve({
                    data: result.data,
                    page: result.meta.current_page - 1,
                    totalCount: result.meta.total_count,
                  })
                })
            })
          }
          actions={[
            {
              icon: 'open_in_browser',
              tooltip: 'Open',
              url: (rowData) => rowData.links.self , 
              hidden: false
            },
            {
              icon: 'edit',
              tooltip: 'Edit',
              url: (rowData) => rowData.links.edit , 
              hidden: false
            },
            {
              icon: 'delete_outline',
              tooltip: 'Delete',
              url: (rowData) => rowData.links.self , 
              hidden: true
            }
          ]}
          options={{
            actionsColumnIndex: -1,
            filtering: true
          }}
          components={{
            Action: props => (

              <a
                //href={props.data.id}
                href={props.action.url(props.data)}
                title={props.action.tooltip}
                className={`btn-outline-secondary btn-sm ${props.action.hidden ? 'hidden' : null}`}
              >
                <Icon>{props.action.icon}</Icon>
                <Typography variant="srOnly">{props.action.tooltip}</Typography>

              </a>
            ),
            Toolbar: props => (
              <div>
                <MTableToolbar {...props} />
                { toolbarContent }
              </div>
            ),
          }}
          
        />
      </div>
    );
  }
}

Datatable.propTypes = {
  title: PropTypes.string,
  remoteUrl: PropTypes.string,
  columns: PropTypes.array,
  toolbarContent: PropTypes.string
};

Datatable.defaultProps = {
  title: "This is the title",
  remoteUrl: "nil",
  columns: [],
  toolbarContent: null
}

export default Datatable
