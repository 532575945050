import React, { useState } from "react";
import PropTypes from "prop-types"

import SelectBasic from "components/SelectBasic"
import deburr from 'lodash/deburr';


class SelectRemote extends React.Component {

    state = {
        fetchedData: [],
        searchCache: []
    };

    setFetchedData(results) {
        this.setState({ fetchedData: results });
    }

    setSearchCache(results) {
        this.setState({ searchCache: results });
    }

    // when user types in the search input we 
    // fetch the remote data 
    inputOnChange = (event) => {
        

        const searchInput = event.target.value.toLowerCase()

        // if the search term is null or less than 3 characters we 
        // return an empty array. NB Our ES search server is configured
        // for search terms > 3 characters
        if (searchInput === "" || searchInput.trim().length < 3 ) {
            this.setFetchedData([]);
        // if the search cache already contains our search term
        // we return the cached data
        } else if ( this.state.searchCache[searchInput] ) {
            this.setFetchedData( this.state.searchCache[searchInput] );
        // else we query the api and assign the fetched data to state and 
        // to a new search cache keyed to our search term
        } else {
           
            this.fetchRemoteData({
                url: this.props.remoteUrl, 
                searchInput
            })
        }
    }

    fetchRemoteData = (fetchProps) => {
        const { url, searchInput } = fetchProps;
        const apiUrl = `${url}?q=${searchInput}`;
    
        fetch(apiUrl)
            .then(res => res.json())
            .then(json => {

                // TODO 
                // we check that the returned json 
                // has a data node. Our server side API 
                // does not handle empty results very well 
                // and returns json with the incorrect root node.
                if (searchInput && "data" in json) {
                    // return json.data
                    const remoteData = json.data //.map(a => a.properties) 

                    this.setFetchedData(remoteData);

                    const newResults = { [searchInput]: remoteData };
                    const newSearchCache = Object.assign({}, this.state.searchCache, newResults);
                    this.setSearchCache(newSearchCache);

                } else {
                    this.setFetchedData( [] );
                }   
            })             
            .catch(error => console.log(error));
    }

    formatValueToDisplay = (item) => {
        if (typeof this.props.formatValueToDisplay === 'function') {
            return this.props.formatValueToDisplay(item);
        } else { 
            return item 
                ? item.name
                : "" 
        }
    }

    // when user selects an item from the dropdown
    handleItemChange = (selection) => {
        if (typeof this.props.onRemoteItemChange === 'function') {
            return this.props.onRemoteItemChange(selection);
        } else {
            null
        }
    }

    getSuggestions = (value, suggestions, { showEmpty = false } = {}) => {

        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;
        let count = 0;
    
        // TODO our geojson is formatted different from our jsonapi
        // this normalizes the format of suggestions
    
        
    
        // const items = ( suggestions.some(suggestion => "properties" in suggestion ) ) 
            // ? suggestions.map(a => a.properties)  
            // : suggestions
        
        return inputLength === 0 && !showEmpty
            ? []
            : suggestions.filter(suggestion => {
            const keep =
                count < 5 
                // search is performned on the server. we don't need to filter on the client if remote
                //&& suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;
    
            if (keep) {
                count += 1;
            }
    
            return keep;
            });
    }

    

    render () {
        return (

            <SelectBasic 
                name = { this.props.name }
                label = { this.props.label }
                inputOnChange = {this.inputOnChange}
                options = { this.state.fetchedData }
                formatValueToDisplay = { this.formatValueToDisplay }
                canCreateNew = {this.props.canCreateNew} 
                onItemChange = {this.handleItemChange} 
                helperText = {this.props.helperText}
                renderSuggestion = {this.props.renderSuggestion} 
                getSuggestions = {this.getSuggestions}
                inputId = {this.props.inputId} 
                error = {this.props.error}
                selectedItem = {this.props.selectedItem}
                labelClassName = { this.props.labelClassName } 

            />
                        
        )
    }
}



//fetchRemoteData.propTypes = {
    //url: PropTypes.string.isRequired,
    //searchInput: PropTypes.string.isRequired,
//};

SelectRemote.propTypes = {
    divesitesRemoteUrl:PropTypes.string,
    regionsRemoteUrl: PropTypes.string,
    countriesRemoteUrl: PropTypes.string,
    options: PropTypes.array,
    name: PropTypes.string,
    label: PropTypes.string,
    remoteUrl: PropTypes.string,
    canCreateNew: PropTypes.bool,
    formatValueToDisplay: PropTypes.func,
    onRemoteItemChange: PropTypes.func,
    renderSuggestion: PropTypes.func,
    helperText: PropTypes.string,
    inpuytId: PropTypes.string,
    error: PropTypes.bool,
    labelClassName: PropTypes.string,
}


SelectRemote.defaultProps = {
    divesitesRemoteUrl: "http.divesite.api",
    regionsRemoteUrl: "http.divesite.api",
    countriesRemoteUrl: "http.divesite.api",
    options: [],
    name: "",
    label: "",
    remoteUrl: "",
    canCreateNew: false,
    formatValueToDisplay: null,
    onRemoteItemChange: null,
    renderSuggestion: null,
    helperText: "",
    inputId: undefined,
    error: false,
    labelClassName: undefined
}




export default SelectRemote