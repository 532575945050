import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from 'activestorage';





Rails.start();
Turbolinks.start();
ActiveStorage.start();

import 'bootstrap/dist/js/bootstrap';

import './activestorage_directupload';

import './shuffle_gallery';




// import "../styles/application.scss";




